import { apiUrlECommerce } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setECommerceList = (payload) => ({
  type: at.SET_ECOMMERCE_LIST,
  payload,
});

// ----------------- API -----------------

export const getECommerceList = (projectId) => () => {
  const url = `${apiUrlECommerce.get.format(projectId)}`;

  return Network.request(url, {
    onSuccess: (response) => setECommerceList(response?.results || []),
    loading: true,
  });
};
export const createOrUpdateECommerceIntegration =
  (projectId, data, options = {}) =>
  () => {
    const id = data?.id;
    const url = id ? `${apiUrlECommerce.get.format(projectId)}${id}/` : apiUrlECommerce.get.format(projectId);

    return Network.request(url, {
      method: id ? "PUT" : "POST",
      data,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const testECommerceIntegration = (projectId, integrationId) => () => {
  const url = `${apiUrlECommerce.testIntegration.format(projectId, integrationId)}`;

  return Network.request(url, {
    loading: true,
  });
};
