import { ReactComponent as IdeasoftLogo } from "~assets/images/e-commerce/ideasoft.svg";
import { ReactComponent as IkasLogo } from "~assets/images/e-commerce/ikas.svg";
import { ReactComponent as ProjeLogo } from "~assets/images/e-commerce/proje.svg";
import { ReactComponent as ShopifyLogo } from "~assets/images/e-commerce/shopify.svg";
import { ReactComponent as TicimaxLogo } from "~assets/images/e-commerce/ticimax.svg";
import { ReactComponent as TrendyolLogo } from "~assets/images/e-commerce/trendyol.svg";
import { ReactComponent as TsoftLogo } from "~assets/images/e-commerce/tsoft.svg";
import ConstantHelper from "~helpers/ConstantHelper";

export const [eCommerceProject, eCommerceProjectOptions, eCommerceProjectMap] = ConstantHelper.typify({
  /** @type {"Trendyol"} */
  YT: "Trendyol",
  /** @type {"Ideasoft"} */
  PD: "Ideasoft",
  /** @type {"Proj-e"} */
  PP: "Proj-e",
  /** @type {"Ticimax"} */
  PT: "Ticimax",
  /** @type {"Shopify"} */
  PS: "Shopify",
  /** @type {"Ikas"} */
  PI: "Ikas",
  /** @type {"TSoft"} */
  PTS: "TSoft",

  // PE: "Email",
  // YE: "Etsy",
  // PW: "Woocommerce",
  // PN: "Next4Biz",
  // YA: "Amazon",
  // YH: "Hepsiburada",
  // YN: "N11",
  // YG: "Gittigidiyor",
  // YP: "PTTAVM",
});
// yt,pd,pp,pt,ps,pi

export const eCommerceDefinitionList = [
  {
    //Trendyol
    key: eCommerceProject.YT,
    name: eCommerceProjectMap[eCommerceProject.YT],
    configParams: [
      { label: "Seller ID", key: "seller_id", required: true, fullWidth: true },
      { label: "Username", key: "username", required: true },
      { label: "Password", key: "password", required: true },
    ],
    logo: TrendyolLogo,
    logoStyles: {},
  },
  {
    //IdeaSoft
    key: eCommerceProject.PD,
    name: eCommerceProjectMap[eCommerceProject.PD],
    configParams: [
      { label: "Base URL", key: "base_url", required: true, type: "url", fullWidth: true },
      { label: "Client ID", key: "client_id", required: true },
      { label: "Client Secret", key: "client_secret", required: true },
      { label: "Access Token", key: "access_token", required: true },
      { label: "Refresh Token", key: "refresh_token", required: true },
    ],
    logo: IdeasoftLogo,
  },
  {
    //Proj-e
    key: eCommerceProject.PP,
    name: eCommerceProjectMap[eCommerceProject.PP],
    configParams: [
      { label: "Base URL", key: "base_url", required: true, type: "url", fullWidth: true },
      { label: "Username", key: "username", required: true },
      { label: "Password", key: "password", required: true },
    ],
    logo: ProjeLogo,
  },
  {
    //Ticimax
    key: eCommerceProject.PT,
    name: eCommerceProjectMap[eCommerceProject.PT],
    configParams: [
      { label: "Base URL", key: "base_url", required: true, type: "url", fullWidth: true },
      { label: "Member Code", key: "member_code", required: true, fullWidth: true },
    ],
    logo: TicimaxLogo,
  },
  {
    //Shopify
    key: eCommerceProject.PS,
    name: eCommerceProjectMap[eCommerceProject.PS],
    configParams: [
      { label: "Domain", key: "domain", required: true, type: "domain", fullWidth: true },
      { label: "Access Token", key: "access_token", required: true, fullWidth: true },
    ],
    logo: ShopifyLogo,
  },
  {
    //Ikas
    key: eCommerceProject.PI,
    name: eCommerceProjectMap[eCommerceProject.PI],
    configParams: [
      { label: "Base URL", key: "base_url", required: true, type: "url", fullWidth: true },
      { label: "Client ID", key: "client_id", required: true, fullWidth: true },
      { label: "Client Secret", key: "client_secret", required: true, fullWidth: true },
      { label: "Store Image ID", key: "store_image_id", required: true, fullWidth: true },
    ],
    logo: IkasLogo,
  },
  {
    //TSoft
    key: eCommerceProject.PTS,
    name: eCommerceProjectMap[eCommerceProject.PTS],
    configParams: [
      { label: "Base URL", key: "base_url", required: true, type: "url", fullWidth: true },
      { label: "Username", key: "user_name", required: true },
      { label: "Password", key: "password", required: true },
    ],
    logo: TsoftLogo,
  },
];
