import { useCallback, useEffect, useMemo, useState } from "react";

import { PalCodeField, PalSwitch, PalTypography, useMonaco } from "@palamar/fe-library";
import { withCardon } from "cardon";
import chroma from "chroma-js";
import { cloneDeep, set } from "lodash";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdChat,
  MdCode,
  MdDelete,
  MdHelp,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlineClose,
  MdPreview,
  MdRestartAlt,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Col, Label, Row } from "reactstrap";

import styled from "@emotion/styled";
import { Help } from "@mui/icons-material";
import { Box, Collapse, Grid, Switch } from "@mui/material";

import PalamarLogo from "~assets/images/logo-filled-notext.svg";
import { ReactComponent as BotSvg } from "~assets/images/webchat/chat-bot.svg";
import LegoLottie from "~assets/lottie/lego.json";
import useQueryParams from "~common/hooks/useQueryParams";
import { useStorage } from "~common/hooks/useStorage";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import { PRDividerLabel } from "~components/Generic/PRDivider";
import PRInput, { PRTextArea, PRTextAreaFormat } from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRPage, { PRCard } from "~components/Generic/PRPage";
import PRPopover from "~components/Generic/PRPopover";
import PRProfile, { PRProfileLottie } from "~components/Generic/PRProfile";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import {
  lowCodeLanguage,
  lowCodeModuleType,
  popupAvatarVisibilityOptions,
  popupFooterMode,
  popupFooterModeOptions,
  popupIconType,
  popupIconTypeOptions,
  popupMinimizeBehaviorOptions,
  popupMinimizeExistingChatOptions,
  popupPositionOptions,
  popupSpeechRecognition,
  popupSpeechRecognitionOptions,
  popupTriggerType,
  popupTriggerTypeOptions,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import ThemeMapper from "~helpers/ThemeMapper";
import Utils from "~helpers/Utils";
import store from "~store";
import { createOrUpdateProcedure, getProcedureList } from "~store/dialogComponents/scenarioManager/actions";
import { selectProcedureList } from "~store/dialogComponents/scenarioManager/selectors";
import {
  initPopupSettingsState,
  patchPopupSettings,
  setPopupSettingsState,
} from "~store/settings/popupSettings/actions";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import "./style.scss";

// const buttonGroupOptionsDefaultOrIcon = [
//   {
//     label: "Default",
//     value: "DF",
//   },
//   {
//     label: "Icon",
//     value: "IC",
//   },
// ];
const languages = {
  TR: "constants.languages.turkish",
  EN: "constants.languages.english",
  DE: "constants.languages.german",
  GR: "constants.languages.greek",
  AR: "constants.languages.arabic",
  UK: "constants.languages.ukrainian",
  RU: "constants.languages.russian",
  PE: "constants.languages.persian",
  FR: "constants.languages.french",
};

const StyledPRPagePermission = styled(PRPage)`
  .card {
    background-color: #fff0f0;
    border-radius: 14px;
  }
`;

const AdvancedPRCard = styled(PRCard)`
  .card-body {
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
  }
  &.card {
    background-color: var(--bs-gray-350);
  }
`;

const StyledPRContainer = styled(PRContainer)`
  max-width: 1140px;
`;
const ClickableGrid = styled(Grid)`
  cursor: pointer;
`;

const JsCodeEditorModal = withCardon(
  function EmbeddedCodeEditorModalContent({ state, get, onClose }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const monaco = useMonaco();
    const currentProject = useSelector(selectCurrentProject);
    const currentBot = useSelector(selectCurrentBot);
    const [code, setCode] = useState(state || "");
    const procedureList = useSelector(selectProcedureList);

    const handleChangeCode = (e) => {
      setCode(e.target.value);
    };
    useEffect(() => {
      if (!currentProject?.id || !currentBot?.id) return;

      dispatch(getProcedureList(currentProject.id, currentBot.id));
    }, [currentProject, currentBot, dispatch]);
    useEffect(() => {
      if (!monaco) return;
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: false,
        noSyntaxValidation: false,
      });
    }, [monaco]);

    const embeddingJsProcedure = useMemo(() => {
      const targetName = "fe_scripts";

      const procedure = procedureList.find((item) => item.name === targetName);
      return procedure;
    }, [procedureList]);

    useEffect(() => {
      if (!embeddingJsProcedure) return;

      setCode(embeddingJsProcedure?.implementation ?? "");
    }, [embeddingJsProcedure]);

    const handleSubmit = async () => {
      get(code)();

      await dispatch(
        createOrUpdateProcedure(currentProject.id, currentBot.id, {
          ...embeddingJsProcedure,
          language: lowCodeLanguage.JAVASCRIPT,
          name: "fe_scripts",
          from_version: embeddingJsProcedure?.last_version > -1 ? embeddingJsProcedure?.last_version : 0,
          module_type: lowCodeModuleType.CHATBOT_MODULES,
          implementation: code,
        })
      );
      await dispatch(getProcedureList(currentProject.id, currentBot.id));
    };

    return (
      <PRModal
        size="xl"
        submitText={t("common.update")}
        title={t("component.chatbotEmbeddedPreview.codeEditorModal.title")}
        onClick={handleSubmit}
        onClose={get(false)}
      >
        {monaco && (
          <PalCodeField
            editorProps={{
              language: "javascript",
              defaultLanguage: "javascript",
            }}
            defaultHeight={800}
            //   excludeFormatList={[PalCodeFieldFormat.markdown, PalCodeFieldFormat.plain]}
            //   format={PalCodeFieldFormat.html}
            value={code}
            onChange={handleChangeCode}
          />
        )}
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

function PopupSettings() {
  const popupSettings = useSelector(selectPopupSettingsState);
  const currentProject = useSelector(selectCurrentProject);

  const queryParams = useQueryParams();
  // const currentBot = useSelector(selectCurrentBot);
  // const sidebar = useSelector(selectSidebar);
  const userInfo = useSelector(selectUserInfo);

  const [modifiedCustomStyle, setModifiedCustomStyle] = useState("");

  // const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [files, setFiles] = useState({});
  const [showPopupPreviewDevMode, setShowPopupPreviewDevMode] = useState(false);
  const [showPopupPreview, setShowPopupPreview] = useStorage("pal_popup_preview", false);

  // const handleTogglePreview = () => {
  //   setIsPreviewVisible(!isPreviewVisible);
  // };

  useEffect(() => {
    setModifiedCustomStyle(popupSettings?.chatbot_info?.style?.custom_css ?? "");
  }, [popupSettings?.chatbot_info?.style?.custom_css]);

  useEffect(() => {
    if (showPopupPreview) {
      window._pproject = [];
      _pproject.push(["_project_token", "preview"]);
      _pproject.push(["url_query", ["preview_mode=true"]]);

      const pp = document.createElement("script");
      pp.type = "text/javascript";
      pp.defer = true;
      pp.id = "popup-preview";
      pp.src = `${import.meta.env.VITE_APP_PUBLIC_URL}/iframe/popup.js`;
      if (import.meta.env.DEV) {
        pp.src = "http://localhost:4000/iframe/popup.js";
        if (showPopupPreviewDevMode) {
          pp.src = `https://${import.meta.env.VITE_APP_PROXY_URL}/iframe/popup.js`;
        }
      }

      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(pp, s);

      //listen palmate-chatbot event
      window.addEventListener("palmate-chatbot", (event) => {
        const type = event?.detail?.type;
        if (type === "init") {
          const iframe = document.getElementById("_palamar_chat_iframe");

          iframe.contentWindow.dashboard ??= {};
          iframe.contentWindow.dashboard.reloadPreviewSettings = () => {
            const currentPopupSettingsState = selectPopupSettingsState(store.getState());
            dispatch(setPopupSettingsState(null, { ...currentPopupSettingsState }));
          };
        }
      });
      return () => {
        pp.remove();
        const iframe = document.getElementById("_palamar_chat_iframe");
        const iframeBalloon = document.getElementById("_palamar_chat_balloon");
        const iframeBalloonBackdrop = document.getElementById("_palamar_chat_balloon_backdrop");
        if (iframe) {
          iframe.remove();
        }
        if (iframeBalloon) {
          iframeBalloon.remove();
        }
        if (iframeBalloonBackdrop) {
          iframeBalloonBackdrop.remove();
        }
      };
    }
  }, [showPopupPreview]);
  const formItemTypeOptions = useMemo(
    () => [
      { value: "Alpha Numeric", label: t("dashboard.popup.alphaNumeric") },
      { value: "Email", label: t("common.email") },
      { value: "Only Numbers", label: t("dashboard.popup.onlyNumbers") },
      { value: "Phone Number", label: t("common.phoneNumber") },
    ],
    [t]
  );
  const handleFileChange = (isLottie) => async (file) => {
    if (!isLottie) {
      const img = await FileHelper.getImage(file);
      const width = img.width;
      const height = img.height;
      if (width !== height || width < 144) {
        AlertHelper.showError(t("dashboard.popup.errors.headerIconSize"));
        return;
      }
    } else {
      const lottieObj = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
          let json;
          try {
            json = JSON.parse(e.target.result);
          } catch {}
          resolve(json);
        };
        reader.readAsText(file);
      });
      if (!lottieObj?.v) {
        AlertHelper.showError(t("dashboard.popup.errors.invalidLottie"));
        return;
      }
    }

    setFiles({ ...files, popup_button_icon_path: [file] });
    if (popupSettings?.popup_style_info?.popup_icon_type === popupIconType.lottie) {
      dispatch(setPopupSettingsState("popup_button_icon_path", file));
    } else {
      const blobUri = URL.createObjectURL(file);
      dispatch(setPopupSettingsState("popup_button_icon_path", blobUri));
    }
  };

  const handleHeaderFileChange = async (file) => {
    const img = await FileHelper.getImage(file);
    const width = img.width;
    const height = img.height;
    if (width !== height || width < 144) {
      AlertHelper.showError(t("dashboard.popup.errors.headerIconSize"));
      return;
    }

    setFiles({ ...files, popup_header_icon_path: [file] });
    const blobUri = URL.createObjectURL(file);
    dispatch(setPopupSettingsState("popup_header_icon_path", blobUri));
  };
  const handleBotJoinedFileChange = async (file) => {
    const img = await FileHelper.getImage(file);
    const width = img.width;
    const height = img.height;
    if (width !== height || width < 144) {
      AlertHelper.showError(t("dashboard.popup.errors.botIconSize"));
      return;
    }

    setFiles({ ...files, popup_bot_icon_path: [file] });
    const blobUri = URL.createObjectURL(file);
    dispatch(setPopupSettingsState("popup_bot_icon_path", blobUri));
  };
  const validate = (showAlert = true) => {
    if (popupSettings.chatbot_languages.length === 0) {
      showAlert && AlertHelper.show(t("dashboard.popup.errors.emptyLanguage"), "error");
      return false;
    }

    if (popupSettings.popup_title.length === 0) {
      showAlert && AlertHelper.show(t("dashboard.popup.errors.emptyTitle"), "error");
      return false;
    }

    if (popupSettings.welcome_form.form_items.length === 0) {
      showAlert && AlertHelper.show(t("dashboard.popup.errors.emptyWelcomeForm"), "error");
      return false;
    }

    for (let index in popupSettings.welcome_form.form_items) {
      if (
        popupSettings.welcome_form.form_items[index].form_key.length === 0 ||
        popupSettings.welcome_form.form_items[index].form_label.length === 0
      ) {
        showAlert && AlertHelper.show(t("dashboard.popup.errors.invalidWelcomeForm"), "error");
        return false;
      }
    }

    if (
      popupSettings?.popup_style_info?.popup_icon_type !== popupIconType.default &&
      popupSettings.popup_button_icon_path === null
    ) {
      showAlert && AlertHelper.show(t("dashboard.popup.errors.emptyButtonIcon"), "error");
      return false;
    }

    if (
      popupSettings?.popup_style_info?.popup_header_icon_type === popupIconType.icon &&
      popupSettings.popup_header_icon_path === null
    ) {
      showAlert && AlertHelper.show(t("dashboard.popup.errors.emptyHeaderIcon"), "error");
      return false;
    }

    if (
      popupSettings?.popup_style_info?.popup_bot_icon_type === popupIconType.icon &&
      popupSettings.popup_bot_icon_path === null
    ) {
      showAlert && AlertHelper.show(t("dashboard.popup.errors.emptyBotIcon"), "error");
      return false;
    }
    return true;
  };

  const handleChange = useCallback(
    (key) => (e) => {
      const newState = cloneDeep(popupSettings);
      newState.popup_style_info ??= {};
      newState.chatbot_info ??= {};
      newState.chatbot_info.style ??= {};

      let value = e.target?.value;
      if (key.startsWith("chatbot_info.")) {
        const subKey = key.replace("chatbot_info.", "");
        if (subKey === "footer_mode") {
          value = e;
        } else if (subKey === "avatar_visibility") {
          value = e;
        } else if (subKey === "speech_recognition_mode") {
          value = e;
        } else if (subKey === "speech_recognition_auto_send") {
          value = e.target.checked;
        } else if (subKey === "minimize_existing_Chat") {
          value = e;
        } else if (subKey === "minimize_behavior") {
          value = e;
        } else if (subKey === "disable_lazy_load") {
          value = e.target.checked;
        } else if (subKey === "enable_advanced_style") {
          value = !popupSettings?.chatbot_info?.enable_advanced_style;

          if (value) {
            const main = popupSettings.popup_primary_color;
            const light = chroma(main).brighten(1.5).hex();
            // const dark = chroma(main).darken(0.7).hex();
            // const titleColor = Utils.determineTextColor(main);
            const contrastColor = Utils.determineTextColor(light);
            const contrastText = contrastColor;
            const titleText = "#fff";

            const advancedStyle = ThemeMapper.initializeStyle({
              primary_color: popupSettings?.chatbot_info?.style?.primary_color || main,
              header_color: popupSettings?.chatbot_info?.style?.header_color || titleText,
              header_background_color: popupSettings?.chatbot_info?.style?.header_background_color || main,
              chat_balloon_customer_text_color:
                popupSettings?.chatbot_info?.style?.chat_balloon_customer_text_color || contrastText,
              chat_balloon_customer_background_color:
                popupSettings?.chatbot_info?.style?.chat_balloon_customer_background_color || main,
              chat_balloon_customer_border:
                popupSettings?.chatbot_info?.style?.chat_balloon_customer_border || `1px solid ${light}`,
              header_icon_color: popupSettings?.chatbot_info?.style?.header_icon_color || titleText,
            });

            //delete specific style bot and agent configuration. these options will be filled using chat_ballon_left_... variants.
            delete advancedStyle.chat_balloon_bot_text_color;
            delete advancedStyle.chat_balloon_bot_background_color;
            delete advancedStyle.chat_balloon_bot_border;

            delete advancedStyle.chat_balloon_agent_text_color;
            delete advancedStyle.chat_balloon_agent_background_color;
            delete advancedStyle.chat_balloon_agent_border;

            newState.chatbot_info.style = advancedStyle;
          }
        } else if (subKey.startsWith("style.")) {
          const subKey2 = key.replace("chatbot_info.style.", "");
          if (subKey2 === "primary_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "header_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "chat_background_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "header_background_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "header_icon_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "chat_balloon_customer_text_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "chat_balloon_customer_background_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          }
          // else if (subKey2 === "chat_balloon_customer_border") {
          //   value = e.target.value;
          // }
          else if (subKey2 === "chat_balloon_left_text_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "chat_balloon_left_background_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          }
          // else if (subKey2 === "chat_balloon_left_border") {
          //  value = e.target.value;
          // } else if (subKey2 === "chat_choice_border") {
          //  value = e.target.value;
          // } else if (subKey2 === "chat_ballon_shadow") {
          //  value = e.target.value;
          // }
          else if (subKey2 === "chat_balloon_filebox_text_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "chat_balloon_filebox_background_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "input_background_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          } else if (subKey2 === "input_text_color") {
            value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
          }
        }
      } else if (key.startsWith("system_chatbot_info.")) {
        const subKey = key.replace("system_chatbot_info.", "");
        if (subKey === "enable_footer_customization") {
          value = e.target.checked;
          if (!value) {
            newState.chatbot_info ??= {};
            newState.chatbot_info.footer_mode = popupFooterMode.visible;
          }
        } else if (subKey === "enable_css_customization") {
          value = e.target.checked;
          if (!value) {
            newState.chatbot_info ??= {};
            newState.chatbot_info.style ??= {};
            newState.chatbot_info.style.custom_css = "";
          }
        }
      } else if (key === "popup_style_info.popup_trigger_type_payload") {
        value = Math.min(Math.max(value, 0), 10_000);
      } else if (key === "popup_primary_color") {
        value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
        const light = chroma(value).brighten(1.5).hex();
        const dark = chroma(value).darken(0.7).hex();
        const titleColor = Utils.determineTextColor(value);
        const contrastColor = Utils.determineTextColor(light);

        newState.popup_style_info.primary_light = light;
        newState.popup_style_info.primary_dark = dark;
        newState.popup_style_info.contrast_color = contrastColor;
        newState.popup_style_info.title_color = titleColor;

        const advancedStyle = ThemeMapper.initializeStyle({
          primary_color: value,
          header_color: titleColor,
          header_background_color: value,
          chat_balloon_customer_text_color: contrastColor,
          chat_balloon_customer_background_color: value,
          chat_balloon_customer_border: `1px solid ${light}`,
          header_icon_color: titleColor,
        });
        //delete specific style bot and agent configuration. these options will be filled using chat_ballon_left_... variants.
        delete advancedStyle.chat_balloon_bot_text_color;
        delete advancedStyle.chat_balloon_bot_background_color;
        delete advancedStyle.chat_balloon_bot_border;

        delete advancedStyle.chat_balloon_agent_text_color;
        delete advancedStyle.chat_balloon_agent_background_color;
        delete advancedStyle.chat_balloon_agent_border;

        newState.chatbot_info.style = advancedStyle;
      } else if (key === "popup_style_info.title_color") {
        value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
      } else if (key === "popup_style_info.contrast_color") {
        value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
      } else if (key === "popup_style_info.primary_light") {
        value = chroma.hsl(e.hsl.h, e.hsl.s, e.hsl.l, e.hsl.a).hex();
      } else if (key === "welcome_form.optional") {
        value = !e.target.checked;
      } else if (
        //select types
        [
          "popup_style_info.popup_position",
          "popup_style_info.popup_header_icon_type",
          "popup_style_info.popup_bot_icon_type",
          "popup_style_info.popup_icon_type",
          "popup_style_info.popup_trigger_type",
        ].includes(key)
      ) {
        if (key === "popup_style_info.popup_icon_type") {
          if (e === popupIconType.icon) {
            newState.popup_button_icon_path = PalamarLogo;
            Utils.urlToFile(PalamarLogo, "defaultHeaderIcon.png").then((file) => {
              setFiles((prevData) => ({ ...prevData, popup_button_icon_path: [file] }));
            });
          } else if (e === popupIconType.lottie) {
            // LegoLottie to plain/text
            const file = new File([JSON.stringify(LegoLottie)], "lottie.json", { type: "text/plain" });
            newState.popup_button_icon_path = file;
            setFiles((prevData) => ({ ...prevData, popup_button_icon_path: [file] }));
          }
        } else if (key === "popup_style_info.popup_header_icon_type") {
          if (e === popupIconType.icon) {
            newState.popup_header_icon_path = PalamarLogo;
            Utils.urlToFile(PalamarLogo, "defaultHeaderIcon.png").then((file) => {
              setFiles((prevData) => ({ ...prevData, popup_header_icon_path: [file] }));
            });
          }
        } else if (key === "popup_style_info.popup_bot_icon_type") {
          if (e === popupIconType.icon) {
            newState.popup_bot_icon_path = PalamarLogo;
            Utils.urlToFile(PalamarLogo, "defaultHeaderIcon.png").then((file) => {
              setFiles((prevData) => ({ ...prevData, popup_bot_icon_path: [file] }));
            });
          }
        }
        value = e;
      } else if (
        [
          "popup_style_info.popup_margin_y",
          "popup_style_info.popup_margin_x",
          "popup_style_info.chat_height",
          "popup_style_info.popup_icon_size",
          "popup_style_info.lottie_offset_x",
          "popup_style_info.lottie_offset_y",
          "popup_style_info.lottie_width",
          "popup_style_info.lottie_height",
          "popup_style_info.lottie_loop_delay",
          "popup_style_info.lottie_loop_speed",
          "popup_style_info.popup_ballon_timeout",
        ].includes(key) &&
        e.target.value !== ""
      ) {
        value = Number(e.target.value);
        if (key === "popup_style_info.popup_margin_x" || key === "popup_style_info.popup_margin_y") {
          value = Math.min(Math.max(value, -64), 512);
        } else if (key === "popup_style_info.chat_height") {
          value = Math.min(Math.max(value, 0), 1440);
        } else if (key === "popup_style_info.popup_icon_size") {
          value = Math.min(Math.max(value, 0), 512);
        } else if (key === "popup_style_info.lottie_offset_x") {
          value = Math.min(Math.max(value, -100), 100);
        } else if (key === "popup_style_info.lottie_offset_y") {
          value = Math.min(Math.max(value, -100), 100);
        } else if (key === "popup_style_info.lottie_width") {
          value = Math.min(Math.max(value, 0), 512);
        } else if (key === "popup_style_info.lottie_height") {
          value = Math.min(Math.max(value, 0), 512);
        } else if (key === "popup_style_info.lottie_loop_delay") {
          value = Math.min(Math.max(value, 0), 100000);
        } else if (key === "popup_style_info.lottie_loop_speed") {
          value = Math.min(Math.max(value, 0.1), 10);
        } else if (key === "popup_style_info.popup_ballon_timeout") {
          value = Math.min(Math.max(value, 0), 100000);
        }
      }
      // dispatch(setPopupSettingsState(key, value));
      set(newState, key, value);
      dispatch(setPopupSettingsState(null, newState));
    },
    [dispatch, popupSettings]
  );

  const handleClickSave = async () => {
    if (!validate()) return;
    const payload = cloneDeep(popupSettings);

    if (popupSettings?.popup_style_info?.popup_margin) {
      delete payload.popup_style_info.popup_margin;
    }

    if (modifiedCustomStyle) {
      payload.chatbot_info ??= {};
      payload.chatbot_info.style ??= {};
      payload.chatbot_info.style.custom_css = modifiedCustomStyle;
    }
    await dispatch(
      patchPopupSettings(currentProject.id, popupSettings.id, payload, FileHelper.getFormDataByFileObj(files))
    );
    handleChange("chatbot_info.style.custom_css")({ target: { value: modifiedCustomStyle } });
  };

  const handleAddWelcomeForm = () => {
    const newWelcomeForm = [
      ...popupSettings.welcome_form.form_items,
      {
        form_label: "",
        form_key: "",
        is_identifier: false,
        is_optional: false,
        form_item_type: formItemTypeOptions[0].value,
        project: currentProject.id,
      },
    ];
    dispatch(setPopupSettingsState("welcome_form.form_items", newWelcomeForm));
  };

  const handleResetPopupStyle = () => {
    dispatch(initPopupSettingsState());
  };

  const handleAddLanguage = (langCodeList) => {
    const newChatbotLanguages = [...langCodeList];
    dispatch(setPopupSettingsState("chatbot_languages", newChatbotLanguages));
  };

  const columns = useMemo(() => {
    const handleChangeWelcomeForm = (key, item) => (e) => {
      const newWelcomeForm = popupSettings.welcome_form.form_items.map((formItem) => {
        if (formItem === item) {
          let value = e.target ? e.target?.value : e;
          if (key === "is_identifier" || key === "is_optional") {
            value = e.target.checked;
          }
          return { ...formItem, [key]: value };
        }
        return formItem;
      });
      dispatch(setPopupSettingsState("welcome_form.form_items", newWelcomeForm));
    };
    const handleChangeMove = (index, direction) => () => {
      const targetIndex = direction === "up" ? index - 1 : index + 1;

      const newWelcomeForm = [...popupSettings.welcome_form.form_items];

      if (targetIndex < 0 || targetIndex >= newWelcomeForm.length) return;
      const sortedData = Utils.arrayMove(newWelcomeForm, index, targetIndex);
      dispatch(setPopupSettingsState("welcome_form.form_items", sortedData));
    };

    const handleDelete = (row) => async () => {
      const isEmptyRow = !row.form_label?.length && !row.form_key?.length;
      if (!isEmptyRow && !(await DialogHelper.showQuestionDeleteWithFormat(row.form_label))) return;

      const newWelcomeForm = popupSettings.welcome_form.form_items.filter((formItem) => formItem !== row);
      dispatch(setPopupSettingsState("welcome_form.form_items", newWelcomeForm));
    };
    return [
      {
        label: t("common.label"),
        key: "form_label",
        render: (row) => <PRInput value={row.form_label} onChange={handleChangeWelcomeForm("form_label", row)} />,
      },
      {
        label: t("common.key"),
        key: "form_key",
        render: (row) => {
          return <PRInput name="form_key" value={row.form_key} onChange={handleChangeWelcomeForm("form_key", row)} />;
        },
      },
      {
        label: t("dashboard.popup.fieldType"),
        key: "field_type",
        style: { minWidth: "160px" },
        render: (row) => {
          return (
            <PRSelect
              isPrimitiveValue
              menuPortal
              defaultValue={formItemTypeOptions[0]}
              isClearable={false}
              options={formItemTypeOptions}
              value={row.form_item_type}
              onChange={handleChangeWelcomeForm("form_item_type", row)}
            />
          );
        },
      },
      {
        label: t("dashboard.popup.identifier"),
        key: "is_identifier",
        render: (row) => (
          <PRInput
            checked={row.is_identifier}
            type="checkbox"
            onChange={handleChangeWelcomeForm("is_identifier", row)}
            // disabled={row.readonly || row.identifier}
          />
        ),
      },
      {
        label: t("dashboard.popup.isOptional"),
        key: "is_optional",
        render: (row) => {
          return (
            <PRInput checked={row.is_optional} type="checkbox" onChange={handleChangeWelcomeForm("is_optional", row)} />
          );
        },
      },
      {
        fixed: "right",
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row, index) => (
          <div className="d-flex justify-content-center">
            <ButtonGroup>
              <PRButton
                outline
                color="primary"
                disabled={row.readonly}
                icon={MdKeyboardArrowUp}
                size="sm"
                tooltipText={t("dashboard.popup.moveUp")}
                onClick={handleChangeMove(index, "up")}
              />

              <PRButton
                outline
                color="primary"
                disabled={row.readonly}
                icon={MdKeyboardArrowDown}
                size="sm"
                tooltipText={t("dashboard.popup.moveDown")}
                onClick={handleChangeMove(index, "down")}
              />
            </ButtonGroup>
            <PRButton
              outline
              className="ms-1"
              color="danger"
              disabled={row.readonly}
              icon={MdDelete}
              size="sm"
              tooltipText="Delete"
              onClick={handleDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [dispatch, popupSettings.welcome_form.form_items, t, formItemTypeOptions]);

  const parentName = [
    {
      label: t("dashboard.popup"),
    },
  ];

  const handleOnBlurValidate = (key) => () => {
    const styleInfo = popupSettings?.popup_style_info;
    if (key === "popup_style_info.popup_margin_x" || key === "popup_style_info.popup_margin_y") {
      const keyXOrY = key === "popup_style_info.popup_margin_x" ? "popup_margin_x" : "popup_margin_y";
      handleChange(key)({ target: { value: Math.min(Math.max(styleInfo?.[keyXOrY], -64), 512) } });
    } else if (key === "popup_style_info.chat_height") {
      handleChange(key)({ target: { value: Math.min(Math.max(styleInfo?.chat_height, 500), 1440) } });
    } else if (key === "popup_style_info.popup_icon_size") {
      handleChange(key)({ target: { value: Math.min(Math.max(styleInfo?.popup_icon_size, 32), 256) } });
    }
  };

  const handleClickShowHidePopupPreview = (e) => {
    setShowPopupPreview(!showPopupPreview);
    if (e.shiftKey) {
      setShowPopupPreviewDevMode(!showPopupPreview);
    } else {
      setShowPopupPreviewDevMode(false);
    }
  };

  const handleApplyCustomStyle = () => {
    handleChange("chatbot_info.style.custom_css")({ target: { value: modifiedCustomStyle } });
  };

  const handleCancelCustomStyle = () => {
    setModifiedCustomStyle(popupSettings?.chatbot_info?.style?.custom_css ?? "");
  };

  const handleChangeCustomStyle = (e) => {
    setModifiedCustomStyle(e.target.value);
  };

  const hasChangeCustomStyle = modifiedCustomStyle != popupSettings?.chatbot_info?.style?.custom_css;

  useEffect(() => {
    (async () => {
      if (queryParams.low_code === "true") {
        await JsCodeEditorModal.show({});

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("low_code");

        HistoryHelper.push({
          pathname: window.location.pathname,
          search: searchParams.toString(),
        });
      } else {
        JsCodeEditorModal.hide();
      }
    })();
  }, [queryParams?.low_code]);

  const actions = useMemo(
    () =>
      [
        userInfo.is_superuser && {
          tooltipText: t("dashboard.intentList.lowCodeEditorButton.tooltip"),
          icon: MdCode,
          color: "primary",
          onClick: () => {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("low_code", "true");

            HistoryHelper.push({
              pathname: window.location.pathname,
              search: searchParams.toString(),
            });
          },
        },
      ].filter(Boolean),
    [t, userInfo.is_superuser]
  );
  return (
    <StyledPRContainer
      actions={actions}
      description={t("dashboard.popup.description")}
      name={t("common.settings")}
      parentName={parentName}
    >
      <PRPage
        actions={[
          {
            icon: MdPreview,
            color: "primary",
            onClick: handleClickShowHidePopupPreview,
            label: showPopupPreview ? t("dashboard.popup.hidePopupPreview") : t("dashboard.popup.showPopupPreview"),
          },
        ]}
        className="pr-popup-chat-settings"
        title={t("dashboard.popup.popupSettings")}
      >
        <Row className="mt-0 g-3">
          <Col lg="6" md="12">
            <Label>{t("common.title")}</Label>
            <PRInput value={popupSettings?.popup_title} onChange={handleChange("popup_title")} />
          </Col>
          <Col lg="6" md="12">
            <Label>{t("dashboard.popup.welcomeTitle")}</Label>
            <PRInput
              value={popupSettings?.popup_style_info?.welcome_title || t("chatbot.welcomePage.title")}
              onChange={handleChange("popup_style_info.welcome_title")}
            />
          </Col>
          <Col lg="6" md="12">
            <Label>{t("dashboard.popup.welcomeMessage")}</Label>
            <PRInput value={popupSettings?.popup_welcome_text} onChange={handleChange("popup_welcome_text")} />
          </Col>
          <Col lg="6" md="12">
            <Label>{t("common.languages")}</Label>
            <PRSelect
              isMulti
              isPrimitiveValue
              options={Object.entries(languages).map(([key, value]) => ({ value: key, label: value }))}
              value={popupSettings?.chatbot_languages}
              onChange={handleAddLanguage}
            />
          </Col>
          <Col lg="6" md="12">
            <Label>{t("dashboard.popup.balloonMessage")}</Label>
            <PRInput value={popupSettings?.welcome_msg} onChange={handleChange("welcome_msg")} />
          </Col>
          <Col lg="6" md="12">
            <Label>
              {t("dashboard.popup.balloonTimeout")}{" "}
              <PRTooltip title={t("dashboard.popup.balloonTimeoutTooltip")}>
                <Help
                  sx={{
                    fontSize: "1rem",
                  }}
                />
              </PRTooltip>
            </Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_ballon_timeout}
              onBlur={handleOnBlurValidate("popup_style_info.popup_ballon_timeout")}
              onChange={handleChange("popup_style_info.popup_ballon_timeout")}
            />
          </Col>
          <Col className="pt-1" lg="4" md="12">
            <Label>{t("dashboard.popup.popupButton")}</Label>
            <PRTooltip title={t("dashboard.popup.popupButtonTooltip")}>
              <span>
                <MdHelp className="ms-1 fs-6" />
              </span>
            </PRTooltip>
            <div>
              <PRSelect
                isPrimitiveValue
                className="mb-2"
                isClearable={false}
                options={popupIconTypeOptions.filter((item) => ![popupIconType.none].includes(item.value))}
                value={popupSettings?.popup_style_info?.popup_icon_type}
                onChange={handleChange("popup_style_info.popup_icon_type")}
              />

              {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.default && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: popupSettings?.popup_primary_color,
                    color: "white",
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  <MdChat style={{ fontSize: "2.3rem" }} />
                </div>
              )}

              {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.icon && (
                <PRProfile img={popupSettings?.popup_button_icon_path} onFileChange={handleFileChange(false)} />
              )}
              {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.lottie && (
                <PRProfileLottie img={popupSettings?.popup_button_icon_path} onFileChange={handleFileChange(true)} />
              )}
            </div>
          </Col>
          <Col className="pt-1" lg="4" md="12">
            <Label>{t("dashboard.popup.headerIcon")}</Label>
            <div>
              <PRSelect
                isPrimitiveValue
                className="mb-2"
                isClearable={false}
                options={popupIconTypeOptions.filter((item) => ![popupIconType.lottie].includes(item.value))}
                value={popupSettings?.popup_style_info?.popup_header_icon_type}
                onChange={handleChange("popup_style_info.popup_header_icon_type")}
              />
              {popupSettings?.popup_style_info?.popup_header_icon_type === popupIconType.default && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: popupSettings?.popup_primary_color,
                    color: "white",
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  <MdChat style={{ fontSize: "2.3rem" }} />
                </div>
              )}

              {popupSettings?.popup_style_info.popup_header_icon_type === popupIconType.icon && (
                <PRProfile img={popupSettings?.popup_header_icon_path} onFileChange={handleHeaderFileChange} />
              )}
            </div>
          </Col>
          <Col className="pt-1" lg="4" md="12">
            <Label>{t("dashboard.popup.botJoinedIcon")}</Label>
            <div>
              <PRSelect
                isPrimitiveValue
                className="mb-2"
                isClearable={false}
                options={popupIconTypeOptions.filter(
                  (item) => ![popupIconType.none, popupIconType.lottie].includes(item.value)
                )}
                value={popupSettings?.popup_style_info?.popup_bot_icon_type}
                onChange={handleChange("popup_style_info.popup_bot_icon_type")}
              />
              {popupSettings?.popup_style_info?.popup_bot_icon_type === popupIconType.default && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    color: "white",
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                  }}
                >
                  <BotSvg className="icon-circle__bot" />
                </div>
              )}

              {popupSettings?.popup_style_info.popup_bot_icon_type === popupIconType.icon && (
                <PRProfile circle img={popupSettings?.popup_bot_icon_path} onFileChange={handleBotJoinedFileChange} />
              )}
            </div>
          </Col>
          <Col lg="4" md="12">
            <Label>{t("dashboard.popup.showUpMode")}</Label>

            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupTriggerTypeOptions}
              value={popupSettings?.popup_style_info?.popup_trigger_type}
              onChange={handleChange("popup_style_info.popup_trigger_type")}
            />
            {popupSettings?.popup_style_info?.popup_trigger_type === popupTriggerType.instant && (
              <div className="d-flex align-items-center"></div>
            )}
            {popupSettings?.popup_style_info?.popup_trigger_type === popupTriggerType.delayed && (
              <div className="d-flex align-items-center mt-2">
                <PRInput
                  className="w-100 pe-2"
                  type="number"
                  value={popupSettings?.popup_style_info?.popup_trigger_type_payload}
                  onChange={handleChange("popup_style_info.popup_trigger_type_payload")}
                />
                <div>{t("dashboard.popup.second")}</div>
              </div>
            )}

            {popupSettings?.popup_style_info?.popup_trigger_type === popupTriggerType.scroll && (
              <div className="d-flex align-items-center mt-2">
                <PRInput
                  className="w-100 pe-2"
                  type="number"
                  value={popupSettings?.popup_style_info?.popup_trigger_type_payload}
                  onChange={handleChange("popup_style_info.popup_trigger_type_payload")}
                />
                <div>px</div>
              </div>
            )}
          </Col>
          <Col lg="4" md="12">
            <Label>
              {t("dashboard.popup.footerMode")}
              {!popupSettings?.system_chatbot_info?.enable_footer_customization && (
                <PRTooltip title="Please contact the administrator to enable footer customization.">
                  <span>
                    <MdHelp className="ms-1 fs-6" />
                  </span>
                </PRTooltip>
              )}
            </Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              isDisabled={!popupSettings?.system_chatbot_info?.enable_footer_customization}
              options={popupFooterModeOptions}
              value={popupSettings?.chatbot_info?.footer_mode}
              onChange={handleChange("chatbot_info.footer_mode")}
            />
          </Col>
          <Col lg="4" md="12">
            <Label>{t("dashboard.popup.avatarVisibility")}</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupAvatarVisibilityOptions}
              value={popupSettings?.chatbot_info?.avatar_visibility}
              onChange={handleChange("chatbot_info.avatar_visibility")}
            />
          </Col>

          <Col lg="4" md="12">
            <Label>{t("dashboard.popup.speechRecognitionMode")}</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupSpeechRecognitionOptions}
              value={popupSettings?.chatbot_info?.speech_recognition_mode}
              onChange={handleChange("chatbot_info.speech_recognition_mode")}
            />
            {popupSettings?.chatbot_info?.speech_recognition_mode === popupSpeechRecognition.enabled && (
              <div>
                <Switch
                  checked={popupSettings?.chatbot_info?.speech_recognition_auto_send}
                  onChange={handleChange("chatbot_info.speech_recognition_auto_send")}
                />
                <Label>{t("dashboard.popup.autoSend")}</Label>
                <PRTooltip title={t("dashboard.popup.autoSendTooltip")}>
                  <span>
                    <MdHelp className="ms-1 fs-6" />
                  </span>
                </PRTooltip>
              </div>
            )}
          </Col>
          <Col lg="4" md="12">
            <Label>
              {t("dashboard.popup.minimizeExistingChat")}
              <PRTooltip title={t("dashboard.popup.minimizeExistingChatTooltip")}>
                <span>
                  <MdHelp className="ms-1 fs-6" />
                </span>
              </PRTooltip>
            </Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupMinimizeExistingChatOptions}
              value={popupSettings?.chatbot_info?.minimize_existing_Chat}
              onChange={handleChange("chatbot_info.minimize_existing_Chat")}
            />
          </Col>
          <Col lg="4" md="12">
            <Label>{t("dashboard.popup.minimizeBehavior")}</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupMinimizeBehaviorOptions}
              value={popupSettings?.chatbot_info?.minimize_behavior}
              onChange={handleChange("chatbot_info.minimize_behavior")}
            />
          </Col>
          <Col lg="4" md="12">
            <Label>
              {t("dashboard.popup.disableLazyLoad")}
              <PRTooltip title={t("dashboard.popup.disableLazyLoadTooltip")}>
                <span>
                  <MdHelp className="ms-1 fs-6" />
                </span>
              </PRTooltip>
            </Label>
            <Box>
              <PalSwitch
                checked={popupSettings?.chatbot_info?.disable_lazy_load}
                onChange={handleChange("chatbot_info.disable_lazy_load")}
              />
            </Box>
          </Col>
        </Row>
      </PRPage>
      {/* <PRDivider className="my-3" color={"secondary-400"} /> */}

      <PRPage
        actions={[
          {
            icon: MdRestartAlt,
            color: "primary",
            onClick: handleResetPopupStyle,
            tooltipText: t("dashboard.popup.resetDefault"),
          },
        ]}
        className="pr-popup-chat-settings"
        title={t("dashboard.popup.popupStyle")}
      >
        <Row className="mt-0 g-3">
          <Col disabled={popupSettings?.chatbot_info?.enable_advanced_style} lg="3" md="12">
            <Label>{t("dashboard.popup.primaryColor")}</Label>
            <PRPopover
              content={
                <>
                  <SketchPicker
                    color={popupSettings?.popup_primary_color}
                    onChange={handleChange("popup_primary_color")}
                  />
                </>
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_primary_color,
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              {/* <MdColorize /> */}
            </PRPopover>
          </Col>
          <Col disabled={popupSettings?.chatbot_info?.enable_advanced_style} lg="3" md="12">
            <Label>{t("dashboard.popup.secondaryColor")}</Label>
            <PRPopover
              content={
                <>
                  <SketchPicker
                    color={popupSettings?.popup_style_info?.primary_light}
                    onChange={handleChange("popup_style_info.primary_light")}
                  />
                </>
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_style_info?.primary_light || "#ffffff",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </PRPopover>
          </Col>
          <Col disabled={popupSettings?.chatbot_info?.enable_advanced_style} lg="3" md="12">
            <Label>{t("dashboard.popup.titleColor")}</Label>
            <PRPopover
              content={
                <SketchPicker
                  color={popupSettings?.popup_style_info?.title_color}
                  onChange={handleChange("popup_style_info.title_color")}
                />
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_style_info?.title_color || "#ffffff",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </PRPopover>
          </Col>
          <Col disabled={popupSettings?.chatbot_info?.enable_advanced_style} lg="3" md="12">
            <Label>{t("dashboard.popup.contrastColor")}</Label>
            <PRPopover
              content={
                <SketchPicker
                  color={popupSettings?.popup_style_info?.contrast_color}
                  onChange={handleChange("popup_style_info.contrast_color")}
                />
              }
            >
              <div className="form-control form-control-color mw-100">
                <div
                  className="form-control"
                  style={{
                    backgroundColor: popupSettings?.popup_style_info?.contrast_color || "#ffffff",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </PRPopover>
          </Col>
          <Col lg="3" md="12">
            <Label>{t("dashboard.popup.popupPosition")}</Label>
            <PRSelect
              isPrimitiveValue
              isClearable={false}
              options={popupPositionOptions}
              value={popupSettings?.popup_style_info?.popup_position}
              onChange={handleChange("popup_style_info.popup_position")}
            />
          </Col>
          {/* <Col lg="3" md="12">
            <Label>Popup Margin(px)</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_margin}
              onBlur={handleOnBlurValidate("popup_style_info.popup_margin")}
              onChange={handleChange("popup_style_info.popup_margin")}
            />
          </Col> */}
          <Col lg="3" md="12">
            <Label>{t("dashboard.popup.popupMarginX")}</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_margin_x ?? popupSettings?.popup_style_info?.popup_margin}
              onBlur={handleOnBlurValidate("popup_style_info.popup_margin_x")}
              onChange={handleChange("popup_style_info.popup_margin_x")}
            />
          </Col>
          <Col lg="3" md="12">
            <Label>{t("dashboard.popup.popupMarginY")}</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_margin_y ?? popupSettings?.popup_style_info?.popup_margin}
              onBlur={handleOnBlurValidate("popup_style_info.popup_margin_y")}
              onChange={handleChange("popup_style_info.popup_margin_y")}
            />
          </Col>
          <Col lg="3" md="12">
            <Label>{t("dashboard.popup.popupIconSize")}</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.popup_icon_size}
              onBlur={handleOnBlurValidate("popup_style_info.popup_icon_size")}
              onChange={handleChange("popup_style_info.popup_icon_size")}
            />
          </Col>
          <Col lg="3" md="12">
            <Label>{t("dashboard.popup.chatHeight")}</Label>
            <PRInput
              type="number"
              value={popupSettings?.popup_style_info?.chat_height}
              onBlur={handleOnBlurValidate("popup_style_info.chat_height")}
              onChange={handleChange("popup_style_info.chat_height")}
            />
          </Col>

          <AdvancedPRCard>
            <Grid container alignItems={"center"} spacing={2}>
              <ClickableGrid item xs onClick={handleChange("chatbot_info.enable_advanced_style")}>
                <PalTypography fontWeight={"bold"}>{t("dashboard.popup.advancedStyle")}</PalTypography>
              </ClickableGrid>
              <ClickableGrid item xs="auto" onClick={handleChange("chatbot_info.enable_advanced_style")}>
                <Switch checked={!!popupSettings?.chatbot_info?.enable_advanced_style} />
              </ClickableGrid>
            </Grid>
            <Collapse in={!!popupSettings?.chatbot_info?.enable_advanced_style}>
              <Row className="mt-0 g-3">
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.primaryColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.primary_color}
                        onChange={handleChange("chatbot_info.style.primary_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: popupSettings?.chatbot_info?.style?.primary_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.headerColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.header_color}
                        onChange={handleChange("chatbot_info.style.header_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: popupSettings?.chatbot_info?.style?.header_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.headerIconColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.header_icon_color}
                        onChange={handleChange("chatbot_info.style.header_icon_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: popupSettings?.chatbot_info?.style?.header_icon_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.headerBackgroundColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.header_background_color}
                        onChange={handleChange("chatbot_info.style.header_background_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: popupSettings?.chatbot_info?.style?.header_background_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.chatBackgroundColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.chat_background_color}
                        onChange={handleChange("chatbot_info.style.chat_background_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: popupSettings?.chatbot_info?.style?.chat_background_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.chatBalloonCustomerTextColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.chat_balloon_customer_text_color}
                        onChange={handleChange("chatbot_info.style.chat_balloon_customer_text_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor:
                            popupSettings?.chatbot_info?.style?.chat_balloon_customer_text_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.chatBalloonCustomerBackgroundColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.chat_balloon_customer_background_color}
                        onChange={handleChange("chatbot_info.style.chat_balloon_customer_background_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor:
                            popupSettings?.chatbot_info?.style?.chat_balloon_customer_background_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.chatBalloonLeftTextColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.chat_balloon_left_text_color}
                        onChange={handleChange("chatbot_info.style.chat_balloon_left_text_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor:
                            popupSettings?.chatbot_info?.style?.chat_balloon_left_text_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.chatBalloonLeftBackgroundColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.chat_balloon_left_background_color}
                        onChange={handleChange("chatbot_info.style.chat_balloon_left_background_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor:
                            popupSettings?.chatbot_info?.style?.chat_balloon_left_background_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.chatBalloonFileboxTextColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.chat_balloon_filebox_text_color}
                        onChange={handleChange("chatbot_info.style.chat_balloon_filebox_text_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor:
                            popupSettings?.chatbot_info?.style?.chat_balloon_filebox_text_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.chatBalloonFileboxBackgroundColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.chat_balloon_filebox_background_color}
                        onChange={handleChange("chatbot_info.style.chat_balloon_filebox_background_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor:
                            popupSettings?.chatbot_info?.style?.chat_balloon_filebox_background_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.inputBackgroundColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.input_background_color}
                        onChange={handleChange("chatbot_info.style.input_background_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: popupSettings?.chatbot_info?.style?.input_background_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="4" md="12">
                  <Label>{t("dashboard.popup.inputTextColor")}</Label>
                  <PRPopover
                    content={
                      <SketchPicker
                        color={popupSettings?.chatbot_info?.style?.input_text_color}
                        onChange={handleChange("chatbot_info.style.input_text_color")}
                      />
                    }
                  >
                    <div className="form-control form-control-color mw-100">
                      <div
                        className="form-control"
                        style={{
                          backgroundColor: popupSettings?.chatbot_info?.style?.input_text_color || "#ffffff",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  </PRPopover>
                </Col>
                <Col lg="8" md="12"></Col>
                <Col lg="6" md="12">
                  <Label>{t("dashboard.popup.chatBalloonCustomerBorder")}</Label>
                  <PRInput
                    value={popupSettings?.chatbot_info?.style?.chat_balloon_customer_border}
                    onChange={handleChange("chatbot_info.style.chat_balloon_customer_border")}
                  />
                </Col>
                <Col lg="6" md="12">
                  <Label>{t("dashboard.popup.chatBalloonLeftBorder")}</Label>
                  <PRInput
                    value={popupSettings?.chatbot_info?.style?.chat_balloon_left_border}
                    onChange={handleChange("chatbot_info.style.chat_balloon_left_border")}
                  />
                </Col>
                <Col lg="6" md="12">
                  <Label>{t("dashboard.popup.chatChoiceBorder")}</Label>
                  <PRInput
                    value={popupSettings?.chatbot_info?.style?.chat_choice_border}
                    onChange={handleChange("chatbot_info.style.chat_choice_border")}
                  />
                </Col>
                <Col lg="6" md="12">
                  <Label>{t("dashboard.popup.chatBallonShadow")}</Label>
                  <PRInput
                    value={popupSettings?.chatbot_info?.style?.chat_ballon_shadow}
                    onChange={handleChange("chatbot_info.style.chat_ballon_shadow")}
                  />
                </Col>

                <Col lg="12" md="12">
                  <Label>
                    <Box alignItems={"center"} display={"flex"}>
                      {t("dashboard.popup.customCSS")}
                      {!popupSettings?.system_chatbot_info?.enable_css_customization && (
                        <PRTooltip title="Please contact the administrator to enable CSS customization.">
                          <Box display={"flex"}>
                            <MdHelp className="ms-1 fs-6" />
                          </Box>
                        </PRTooltip>
                      )}
                    </Box>
                  </Label>
                  <PRTextArea
                    editorMode
                    disabled={!popupSettings?.system_chatbot_info?.enable_css_customization}
                    excludeFormatList={[PRTextAreaFormat.html, PRTextAreaFormat.markdown, PRTextAreaFormat.plain]}
                    format={PRTextAreaFormat.css}
                    toolbarComponent={
                      <Box alignItems="center" display="flex" gap={0.5}>
                        {hasChangeCustomStyle && (
                          <>
                            <PRButton
                              className="fw-bold"
                              color="primary"
                              disabled={!popupSettings?.system_chatbot_info?.enable_css_customization}
                              onClick={handleApplyCustomStyle}
                            >
                              {t("common.apply")}
                            </PRButton>
                            <PRButton
                              outline
                              className="fw-bold"
                              color="danger"
                              disabled={!popupSettings?.system_chatbot_info?.enable_css_customization}
                              icon={MdOutlineClose}
                              tooltipText={t("common.cancel")}
                              onClick={handleCancelCustomStyle}
                            />
                          </>
                        )}
                      </Box>
                    }
                    value={modifiedCustomStyle}
                    // value={popupSettings?.chatbot_info?.style?.custom_css}
                    onChange={handleChangeCustomStyle}
                  />
                </Col>
              </Row>
            </Collapse>
          </AdvancedPRCard>
        </Row>
        {popupSettings?.popup_style_info?.popup_icon_type === popupIconType.lottie && (
          <>
            <PRDividerLabel className="mt-3" color={"secondary"}>
              {t("dashboard.popup.lottieSettings")}
            </PRDividerLabel>
            <Row className="mt-0 g-3">
              <Col lg="2" md="12">
                <Label>{t("common.width")}</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_width}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_width")}
                  onChange={handleChange("popup_style_info.lottie_width")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>{t("common.height")}</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_height}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_height")}
                  onChange={handleChange("popup_style_info.lottie_height")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>{t("dashboard.popup.offsetX")}</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_offset_x}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_offset_x")}
                  onChange={handleChange("popup_style_info.lottie_offset_x")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>{t("dashboard.popup.offsetY")}</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_offset_y}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_offset_y")}
                  onChange={handleChange("popup_style_info.lottie_offset_y")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>{t("dashboard.popup.loopDelay")}</Label>
                <PRInput
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_loop_delay}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_loop_delay")}
                  onChange={handleChange("popup_style_info.lottie_loop_delay")}
                />
              </Col>
              <Col lg="2" md="12">
                <Label>{t("dashboard.popup.loopSpeed")}</Label>
                <PRInput
                  step={0.1}
                  type="number"
                  value={popupSettings?.popup_style_info?.lottie_loop_speed?.toFixed(1)}
                  onBlur={handleOnBlurValidate("popup_style_info.lottie_loop_speed")}
                  onChange={handleChange("popup_style_info.lottie_loop_speed")}
                />
              </Col>
            </Row>
          </>
        )}
      </PRPage>

      <PRPage
        actions={[
          {
            icon: MdAdd,
            color: "success-400",
            onClick: handleAddWelcomeForm,
            tooltipText: t("common.add"),
          },
        ]}
        className="pr-popup-chat-settings"
        title={t("dashboard.popup.welcomeForm")}
      >
        <div>
          <PRTable inline noPagination columns={columns} data={popupSettings?.welcome_form?.form_items} />
        </div>

        <Row className="mt-1">
          <Col xs>
            <Switch checked={!popupSettings?.welcome_form?.optional} onChange={handleChange("welcome_form.optional")} />
            <Label className="me-2" size="md">
              {t("dashboard.popup.requireIdentityForm")}
              <PRTooltip title={t("dashboard.popup.requireIdentityFormTooltip")}>
                <span>
                  <MdHelp className="ms-1 fs-6" />
                </span>
              </PRTooltip>
            </Label>
          </Col>
          <Col xs="auto">
            <PRButton minSize="md" onClick={handleClickSave}>
              {t("common.save")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>

      {!!userInfo.is_superuser && (
        <StyledPRPagePermission className="pr-popup-chat-settings " title={t("dashboard.popup.popupPermissions")}>
          <Row className="mt-0 g-3">
            <Col className="d-flex align-items-center" xs="12">
              <Switch
                checked={popupSettings?.system_chatbot_info?.enable_footer_customization}
                onChange={handleChange("system_chatbot_info.enable_footer_customization")}
              />
              {t("dashboard.popup.enableFooterCustomization")}
            </Col>
            <Col className="d-flex align-items-center" xs="12">
              <Switch
                checked={popupSettings?.system_chatbot_info?.enable_css_customization}
                onChange={handleChange("system_chatbot_info.enable_css_customization")}
              />
              {t("dashboard.popup.enableCustomCSS")}
            </Col>
          </Row>
        </StyledPRPagePermission>
      )}
    </StyledPRContainer>
  );
}

export default PopupSettings;
