import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import PRContainer from "~components/Generic/PRContainer";
import { eCommerceDefinitionList } from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import { ECommerceItem, IntegrationDetailModal, ManualIntegrationModal } from "~pages/Onboarding";
import {
  createOrUpdateECommerceIntegration,
  getECommerceList,
  testECommerceIntegration,
} from "~store/settings/eCommerce/actions";
import { selectECommerceList } from "~store/settings/eCommerce/selectors";
import { selectCurrentProject } from "~store/user/selectors";

// const addPlusPrefix = (value) => (value ? (value.startsWith("+") ? value : `+${value}`) : value);

export default function ECommerce() {
  const { t } = useTranslation();
  const currentProject = useSelector(selectCurrentProject);
  const dispatch = useDispatch();
  const eCommerceList = useSelector(selectECommerceList);

  useEffect(() => {
    if (currentProject?.id) {
      dispatch(getECommerceList(currentProject.id));
    }
  }, [dispatch, currentProject?.id]);

  const handleClickShowDetails = (integration) => () => {
    const eCommerceDefinition = eCommerceDefinitionList.find((item) => {
      //NOTE: (BUG) predefined key before comparison. This issues a bug for comparison and won't work as expected.
      const key = item.key;
      return key === integration.platform_type;
    });

    IntegrationDetailModal.show({
      integration: integration?.credentials,
      labelDefinition: eCommerceDefinition.configParams.reduce((acc, item) => {
        acc[item.key] = item.label;
        return acc;
      }, {}),
    });
  };

  const handleManualIntegration = (integrationName) => async () => {
    const integration = eCommerceDefinitionList.find((item) => item.key === integrationName);
    const inputFields = integration.configParams.map((item) => {
      return {
        ...item,
        label: item.label,
        key: item.key,
        required: item.required,
      };
    });
    const integrationData = eCommerceList?.find((item) => item.platform_type === integrationName);
    const state = integrationData?.credentials || {};
    const responseState = await ManualIntegrationModal.show({
      integrationName: integration.name,
      inputFields,
      state,
      showDelete: true,
    });
    if (responseState) {
      const newState = {
        ...responseState,
      };
      if (newState?.access_token && newState?.access_token === state?.access_token) {
        delete newState.access_token;
      }
      if (newState?.refresh_token && newState?.refresh_token === state?.refresh_token) {
        delete newState.refresh_token;
      }
      if (newState?.client_secret && newState?.client_secret === state?.client_secret) {
        delete newState.client_secret;
      }
      if (newState?.password && newState?.password === state?.password) {
        delete newState.password;
      }
      if (newState?.member_code && newState?.member_code === state?.member_code) {
        delete newState.member_code;
      }
      await dispatch(
        createOrUpdateECommerceIntegration(currentProject.id, {
          ...integrationData,
          credentials: newState,
        })
      );
      await dispatch(getECommerceList(currentProject.id));
    }
  };
  return (
    <PRContainer
      className="pr-ecommerce"
      description={t("dashboard.eCommerce.description")}
      name={t("common.settings")}
      parentName={t("dashboard.eCommerce")}
    >
      <Grid container spacing={2}>
        {eCommerceDefinitionList.map((item) => {
          //NOTE: (BUG) predefined key before comparison. This issues a bug for comparison and won't work as expected.
          const key = item.key;
          const eCommerce = eCommerceList.find((item) => item.platform_type === key);
          const credentialsObj = eCommerce?.credentials;
          const isAllCredentialsFilled = item.configParams.every((param) => {
            return credentialsObj?.[param.key];
          });

          const handleClickTest = async () => {
            const testResponse = await dispatch(testECommerceIntegration(currentProject.id, eCommerce.id));
            const isSuccess = testResponse?.status === true;
            if (isSuccess) {
              AlertHelper.showSuccess(t("common.integrationTest.success"));
            } else {
              AlertHelper.showWarning(t("common.integrationTest.fail"));
            }
          };
          return (
            <Grid key={item.key} item md={4} sm={6} xl={3} xs={12}>
              <ECommerceItem
                // desc={`Connect your ${item.name} account to chat with your customers.`}
                image={item.logo}
                imageStyles={item.logoStyles}
                isDone={isAllCredentialsFilled}
                name={item.name}
                onClick={handleManualIntegration(item.key)}
                onClickDetail={handleClickShowDetails(eCommerce)}
                onClickTest={handleClickTest}
                {...(isAllCredentialsFilled && { onClickConfigure: handleManualIntegration(item.key) })}
              />
            </Grid>
          );
        })}
      </Grid>
    </PRContainer>
  );
}
