import ConstantHelper from "~helpers/ConstantHelper";

export const [onboardingRagStatus, onboardingRagStatusOptions, onboardingRagStatusMap] = ConstantHelper.typify({
  Processing: window.t("onboarding.ragStatus.processing"),
  Processed: window.t("onboarding.ragStatus.processed"),
  Error: window.t("onboarding.ragStatus.error"),
  Fresh: window.t("onboarding.ragStatus.fresh"),
});

export const [onboardingRagType, onboardingRagTypeOptions, onboardingRagTypeMap] = ConstantHelper.typify({
  webpage: window.t("onboarding.ragType.webpage"),
  file: window.t("onboarding.ragType.file"),
  content: window.t("onboarding.ragType.content"),
});

export const OnboardingContentSeparator = "|||";

export const [onboardingStatus, onboardingStatusOptions, onboardingStatusMap] = ConstantHelper.typify({
  complete: "Complete",
  cancel: "Cancel",
});

export const [onboardingProjectStatus, onboardingProjectStatusOptions, onboardingProjectStatusMap] =
  ConstantHelper.typify({
    ONB: "Onboarding",
    ACT: "Active",
  });
