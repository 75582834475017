import { apiUrlProject, apiUrlProjectSettings } from "~constants";
import Network from "~helpers/Network";

import * as ta from "./actionTypes";

export const setProject = (payload) => ({
  type: ta.SET_PROJECT,
  payload: {
    ...payload,
    from_template: payload?.from_template || 0,
  },
});

export const setProjectTemplates = (payload) => ({
  type: ta.SET_PROJECT_TEMPLATES,
  payload,
});

export const setProjectLanguages = (payload) => ({
  type: ta.SET_PROJECT_LANGUAGES,
  payload,
});

// ----------------- API -----------------

export const getProject =
  (projectId, options = {}) =>
  () => {
    const url = `${apiUrlProject.get}${projectId}/`;

    return Network.request(url, {
      onSuccess: setProject,
      loading: true,
      ...options,
    });
  };

export const getProjectTemplates = () => () => {
  const url = `${apiUrlProject.getTemplates}`;

  return Network.request(url, {
    loading: true,
    onSuccess: setProjectTemplates,
  });
};

export const getProjectLanguages = () => () => {
  const url = `${apiUrlProject.getLanguages}`;

  return Network.request(url, {
    onSuccess: (response) => setProjectLanguages(response.results),
  });
};

export const createOrUpdateProject =
  (data, options = {}) =>
  () => {
    const url = data.id ? `${apiUrlProject.get}${data.id}/` : apiUrlProject.get;

    return Network.request(url, {
      method: data.id ? "PUT" : "POST",
      data,
      onSuccess: (response) => setProject(response),
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const deleteProject = (projectId) => () => {
  const url = `${apiUrlProject.get}${projectId}/`;

  return Network.request(url, {
    method: "DELETE",
    onSuccess: () => setProject({}),
    successMsg: true,
    loading: true,
  });
};

export const updateProjectSettings =
  (projectId, data, options = {}) =>
  () => {
    const { projectSettingsId, ...restData } = data || {};
    const url = apiUrlProjectSettings.get.format(projectId, projectSettingsId || projectId);

    return Network.request(url, {
      method: "PUT",
      data: restData,
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const uploadProjectFile = (projectId, file, type) => (_dispatch, _getState) => {
  const url = apiUrlProject.getFiles.format(projectId);

  const form = new FormData();
  form.append("file", file);
  form.append("file_type", type);
  return Network.request(url, {
    method: "POST",
    data: form,
    loading: true,
    successMsg: true,
  });
};

export const deleteProjectFile = (projectId, fileId) => (_dispatch, _getState) => {
  const url = apiUrlProject.getFiles.format(projectId) + fileId + "/";

  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};
