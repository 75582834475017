import i18next from "i18next";

import { apiUrlInvitation, apiUrlInviteUser, apiUrlPermission } from "~constants";
import Network from "~helpers/Network";

/**
 * @param {number} projectId
 * @returns
 */
async function getProjectPermissions(projectId) {
  return Network.request(apiUrlPermission.get.format(projectId));
}

async function putProjectPermissions(projectId, payload) {
  return await Network.request(apiUrlPermission.get.format(projectId), {
    method: "PUT",
    data: payload,
    successMsg: i18next.t("project.settings.permissionUpdateSuccess"),
  });
}

function postInviteUser(projectId, payload) {
  return Network.request(apiUrlInviteUser.get.format(projectId), {
    method: "POST",
    data: payload,
    successMsg: i18next.t("project.settings.inviteSuccess"),
  });
}

function getInvitedUsers(projectId) {
  return Network.request(apiUrlInvitation.get.format(projectId));
}

export { getProjectPermissions, postInviteUser, putProjectPermissions, getInvitedUsers };
