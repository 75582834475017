import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  webpage: null,
};

const onboarding = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_SOURCE_WEB_PAGE:
        draft.webpage = action.payload;
        break;
    }
  });

export default onboarding;
