// export const [lowCodeModuleType, lowCodeModuleTypeOptions, lowCodeModuleTypeMap] = ConstantHelper.typify({
//   CHATBOT_MODULES: "Chatbot Module",
//   INTEGRATION_MODULES: "Integration Module",
//   SINGLE_RUN_MODULES: "Single Run Module",
//   STATISTICS_MODULES: "Statistics Module",
// });

import ConstantHelper from "~helpers/ConstantHelper";

export const lowCodeModuleType = {
  CHATBOT_MODULES: 0,
  INTEGRATION_MODULES: 1,
  SINGLE_RUN_MODULES: 2,
  STATISTICS_MODULES: 3,
  RESERVATION_MODULES: 4,
  PRODUCT_MODULES: 5,
  INSIGHT_MODULES: 6,
};

export const [lowCodePlatformType, lowCodePlatformTypeOptions, lowCodePlatformTypeMap] = ConstantHelper.typify({
  BOT: "Bot",
  PRODUCT: "Product",
});

export const [lowCodeLanguage, lowCodeLanguageOptions, lowCodeLanguageMap] = ConstantHelper.typify({
  PYTHON: "Python",
  JAVASCRIPT: "Javascript",
});
