import { apiUrlCompany } from "~constants";
import Network from "~helpers/Network";

import * as ta from "./actionTypes";

export const setCompany = (payload) => ({
  type: ta.SET_COMPANY,
  payload,
});

// ----------------- API -----------------

export const getCompany = (id) => () => {
  const url = `${apiUrlCompany.get}${id}/`;

  return Network.request(url, {
    onSuccess: setCompany,
    loading: true,
  });
};

export const createOrUpdateCompany =
  (data, options = {}) =>
  () => {
    const isDataFormData = data instanceof FormData;
    const id = isDataFormData ? data.get("id") : data?.id;

    const url = id ? `${apiUrlCompany.get}${id}/` : apiUrlCompany.get;

    return Network.request(url, {
      method: id ? "PUT" : "POST",
      data,
      onSuccess: (response) => setCompany(response),
      loading: true,
      successMsg: true,
      ...options,
    });
  };

export const deleteProject = (id) => () => {
  const url = `${apiUrlCompany.get}${id}/`;

  return Network.request(url, {
    method: "DELETE",
    onSuccess: () => setCompany({}),
    successMsg: true,
    loading: true,
  });
};
