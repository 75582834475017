import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import styled from "@emotion/styled";
import { Done, WarningAmber } from "@mui/icons-material";
import { Box } from "@mui/material";

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: absolute;
  width: 100%;
  height: calc(100%);
  padding: 30px 50px;
  @media (max-width: 600px) {
    padding: 30px 20px;
  }

  overflow-y: auto;
  svg {
    flex: 0 0 auto;
  }

  .message {
    text-align: center;
    font: normal normal 600 24px/34px MuseoModerno;
    color: ${(props) => props.theme.palette.primary.dark};
    &-countdown {
      font-size: 16px;
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }

  .icon {
    font-size: 48px;
    margin-top: 8px;
  }
`;
const COUNTDOWN = 3;
const IntegrationCallbackPage = () => {
  const [countDown, setCountDown] = useState(COUNTDOWN);
  const [treeDotsCount, setTreeDotsCount] = useState(0);
  const { t } = useTranslation();

  const code = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    return code;
  }, []);

  useEffect(() => {
    if (!window.opener || !code) return;

    const timer = setTimeout(() => {
      window.opener.postMessage({ code }, "*");

      window.close();
    }, COUNTDOWN * 1000);

    return () => clearTimeout(timer);
  }, [code]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTreeDotsCount((prev) => {
        if (prev === 2) {
          return 0;
        }
        return prev + 1;
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const threeDoComp = (
    <Box component="span">
      <Box component="span" visibility={treeDotsCount >= 0 ? "visible" : "hidden"}>
        .
      </Box>
      <Box component="span" visibility={treeDotsCount >= 1 ? "visible" : "hidden"}>
        .
      </Box>
      <Box component="span" visibility={treeDotsCount >= 2 ? "visible" : "hidden"}>
        .
      </Box>
    </Box>
  );

  return (
    <StyledDiv>
      <Box alignItems="center" display="flex" flexDirection={"column"} height="100%" justifyContent="center">
        {window.opener ? (
          <>
            <Done className="icon" color="success" />
            <div className="message">{t("onboarding.integrationCallbackPage.successMessage")}</div>
            <div className="message message-countdown">
              {t("onboarding.integrationCallbackPage.redirecting").format(countDown)}
              {threeDoComp}
            </div>
          </>
        ) : (
          <>
            <WarningAmber className="icon" color="error" />
            <div className="message">{t("onboarding.integrationCallbackPage.error")}</div>
          </>
        )}
      </Box>
    </StyledDiv>
  );
};

export default IntegrationCallbackPage;
