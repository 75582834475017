import { useState } from "react";

import { PalButton, PalDialog, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";

import { Box, CircularProgress } from "@mui/material";

const IntegrationLoadingModal = withCardon(
  function IntegrationLoadingModalContent({ permanent, get }) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const handleClose = () => {
      if (permanent) return;
      get(false)();
      setOpen(false);
    };
    return (
      <PalDialog disableBackdropClick={permanent} open={open} scroll="body" onClose={handleClose}>
        <PalDialog.Content dividers={false}>
          <PalDialog.ContentText>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              gap={2}
              height="100%"
              justifyContent="center"
            >
              <PalTypography fontWeight={600} textAlign={"center"} variant="h6">
                {t("component.integrationLoadingModal.description")}
              </PalTypography>

              <CircularProgress size={42} />
            </Box>
          </PalDialog.ContentText>
        </PalDialog.Content>
        {!permanent && (
          <PalDialog.Actions>
            <PalButton onClick={handleClose}>{t("common.cancel")}</PalButton>
          </PalDialog.Actions>
        )}
      </PalDialog>
    );
  },
  { destroyOnHide: true }
);
export default IntegrationLoadingModal;
