import { all, fork } from "redux-saga/effects";

import announcementSaga from "./announcement/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import AuthSaga from "./auth/login/saga";
import AccountSaga from "./auth/register/saga";
import dialogComponentsSaga from "./dialogComponents/saga";
import notificationSaga from "./notification/saga";
import settingsSaga from "./settings/saga";
import socketSaga from "./socket/saga";
import userSaga from "./user/user.saga";

export default function* rootSaga() {
  yield all([
    fork(announcementSaga),
    fork(ForgetSaga),
    fork(AuthSaga),
    fork(AccountSaga),
    fork(dialogComponentsSaga),
    fork(settingsSaga),
    fork(socketSaga),
    fork(notificationSaga),
    fork(userSaga),
  ]);
}
