import { all, put, takeLatest } from "redux-saga/effects";

import { getProject } from "~store/settings/projectSettings/actions";

import { setSelectedBot } from "./user.actions";
import * as at from "./user.types";

function* handleSetSelectedProject({ payload }) {
  //   console.log("handleSetSelectedProject", payload);

  const { from_template } = payload || {};
  if (!from_template && from_template !== 0) {
    const result = yield put(
      getProject(payload.id, {
        onSuccess: () => {},
      })
    );
    yield put(
      setSelectedBot({
        ...payload,
        company: result.company,
        creator: result.creator,
        from_template: result.from_template,
        main_language: result.main_language,
        status: result.status,
      })
    );
  }
  yield null;
}
function* userSaga() {
  yield all([takeLatest(at.SET_SELECTED_PROJECT, handleSetSelectedProject)]);
}

export default userSaga;
