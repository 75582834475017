import chroma from "chroma-js";

import { popupSettingsMapper } from "~constants";
import IFrameHelper from "~helpers/IFrameHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import Network from "~helpers/Network";
import { selectUserInfo } from "~store/user/selectors";

import * as actionTypes from "./actionTypes";

export const setPopupSettingsState = (key, value) => ({
  type: actionTypes.SET_POPUP_SETTING_STATE,
  payload: { key, value },
});

export const resetPopupSettingsState = () => ({
  type: actionTypes.RESET_POPUP_SETTING_STATE,
});

export const initPopupSettingsState = () => ({
  type: actionTypes.INIT_POPUP_SETTING_STATE,
});

export const getPopupSettings =
  (
    project,
    options = {
      skipUpdate: false,
    }
  ) =>
  async (dispatch) => {
    const response = await Network.request(`project/${project}/project-settings/`, {
      loading: true,
    });
    let settingsItem = response?.results?.[0];
    if (settingsItem) {
      const main = settingsItem.popup_primary_color || "#28AAE1";
      const light = settingsItem.popup_style_info?.primary_light || chroma(main).brighten(1.5).hex();
      const dark = settingsItem.popup_style_info?.primary_dark || chroma(main).darken(0.7).hex();
      const contrastText = settingsItem.popup_style_info?.contrast_color || "#fff";
      const titleText = settingsItem.popup_style_info?.title_color || "#fff";

      settingsItem.popup_primary_color = main;
      settingsItem.popup_style_info = {
        ...(settingsItem?.popup_style_info || {}),
        contrast_color: contrastText,
        primary_light: light,
        title_color: titleText,
        primary_dark: dark,
      };
      settingsItem = popupSettingsMapper(settingsItem);
      if (!options?.skipUpdate) {
        IFrameHelper.setPropertyToRoot([
          ["--webchat-icon-margin-x", `${settingsItem.popup_style_info.popup_margin_x}px`],
          ["--webchat-icon-margin-y", `${settingsItem.popup_style_info.popup_margin_y}px`],
          ["--webchat-icon-size", `${settingsItem.popup_style_info.popup_icon_size}px`],
          ["--webchat-height", `${settingsItem.popup_style_info.chat_height}px`],
        ]);
        dispatch(setPopupSettingsState(null, settingsItem));
      }
      return settingsItem;
    }
    return response;
  };

export const patchPopupSettings =
  (
    project,
    settingsId,
    popupSettings,
    formFiles = null,
    options = {
      loading: true,
      successMsg: true,
    }
  ) =>
  async (_dispatch, _getState) => {
    if (options?.loading !== false) {
      LoadingHelper.open();
    }
    try {
      const shallowPopupSettings = { ...popupSettings };
      delete shallowPopupSettings["popup_button_icon_path"];
      delete shallowPopupSettings["popup_header_icon_path"];
      delete shallowPopupSettings["popup_bot_icon_path"];
      const userInfo = selectUserInfo(_getState());
      if (!userInfo?.is_superuser) {
        delete shallowPopupSettings["system_chatbot_info"];
      }

      if ([...formFiles.keys()].length) {
        await Network.request(`project/${project}/project-settings/${settingsId}/`, {
          method: "PATCH",
          data: formFiles,
        });
      }
      await Network.request(`project/${project}/project-settings/${settingsId}/`, {
        method: "PATCH",
        data: shallowPopupSettings,
        successMsg: options?.successMsg !== false,
      });
    } finally {
      if (options?.loading !== false) {
        LoadingHelper.close();
      }
    }
  };
