import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

// export const chatbotPreviewMessages = {
//   setPreviewSettings: "setPreviewSettings",
//   initLoadDone: "initLoadDone",
// };
const asyncReader = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      let data = e.target.result;
      resolve(data);
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsText(file);
  });
};
if (location.pathname.endsWith("/bot-preview")) {
  document.body.style.backgroundColor = "transparent";
}
export default function ChatbotPreview() {
  const dispatch = useDispatch();
  const [initDone, setInitDone] = useState(false);

  //   useEffect(() => {
  //     const setPreviewSettings = async (state) => {
  //       const iframeWindow = document.getElementById("_palamar_chat_iframe")?.contentWindow;
  //       const framePopup = iframeWindow?.popup;
  //       await framePopup?.enablePreviewMessages({ botName: state.popup_title });
  //       // const settingState = selectPopupSettingsState(store.getState());
  //       const welcomeFormBodyForPopup = {
  //         // form_items: settingState.welcome_form?.form_items,
  //         // popup_button_icon_path: settingState.popup_button_icon_path,
  //         // popup_button_type: settingState.popup_button_type,
  //         // popup_header_icon_path: settingState.popup_header_icon_path,
  //         // popup_header_icon_type: settingState.popup_header_icon_type,
  //         // popup_bot_icon_type: settingState.popup_bot_icon_type,
  //         // popup_bot_icon_path: settingState.popup_bot_icon_path,
  //         // popup_primary_color: settingState.popup_primary_color,
  //         // popup_title: settingState.popup_title,
  //         // popup_welcome_text: settingState.popup_welcome_text,
  //         // start_anonymous_chat: settingState.welcome_form?.optional,
  //         // welcome_msg: settingState.welcome_msg,
  //         // popup_style_info: settingState.popup_style_info,
  //         // chatbot_info: settingState.chatbot_info,
  //         // system_chatbot_info: settingState.system_chatbot_info,
  //         ...state,
  //       };

  //       const primaryColor = "#50C8F4";
  //       const light = chroma(primaryColor).brighten(1.5).hex();
  //       // const dark = chroma(primaryColor).darken(0.7).hex();
  //       const titleColor = Utils.determineTextColor(primaryColor);
  //       const contrastColor = Utils.determineTextColor(light);
  //       const advancedStyle = ThemeMapper.initializeStyle({
  //         primary_color: primaryColor,
  //         header_color: titleColor,
  //         header_background_color: primaryColor,
  //         chat_balloon_customer_text_color: contrastColor,
  //         chat_balloon_customer_background_color: primaryColor,
  //         chat_balloon_customer_border: `1px solid ${light}`,
  //         header_icon_color: titleColor,
  //       });
  //       //delete specific style bot and agent configuration. these options will be filled using chat_ballon_left_... variants.
  //       delete advancedStyle.chat_balloon_bot_text_color;
  //       delete advancedStyle.chat_balloon_bot_background_color;
  //       delete advancedStyle.chat_balloon_bot_border;

  //       delete advancedStyle.chat_balloon_agent_text_color;
  //       delete advancedStyle.chat_balloon_agent_background_color;
  //       delete advancedStyle.chat_balloon_agent_border;

  //       welcomeFormBodyForPopup.chatbot_info ??= {};
  //       welcomeFormBodyForPopup.chatbot_info = {
  //         ...welcomeFormBodyForPopup.chatbot_info,
  //         enable_advanced_style: true,
  //         style: advancedStyle,
  //       };

  //       if (welcomeFormBodyForPopup.popup_button_icon_path instanceof File) {
  //         const file = welcomeFormBodyForPopup.popup_button_icon_path;
  //         const result = await asyncReader(file);
  //         welcomeFormBodyForPopup.popup_button_icon_path = result;
  //       }

  //       const mappedData = popupSettingsMapper(welcomeFormBodyForPopup);
  //       framePopup.IFrameHelper.setPropertyToRoot([
  //         ["--webchat-icon-margin-x", `${mappedData.popup_style_info.popup_margin_x}px`],
  //         ["--webchat-icon-margin-y", `${mappedData.popup_style_info.popup_margin_y}px`],
  //         ["--webchat-icon-size", `${mappedData.popup_style_info.popup_icon_size}px`],
  //         ["--webchat-height", `${mappedData.popup_style_info.chat_height}px`],
  //       ]);
  //       framePopup.setWelcomeOptions(mappedData);
  //       // framePopup.setViewModeDelayed(settingState.welcome_form?.optional ? "chat" : "welcome");
  //       framePopup.setViewModeDelayed("chat");
  //       framePopup.setWelcomeMsgShowedStatus(false);
  //       framePopup.setPopupSizeModePosition(mappedData?.popup_style_info?.popup_position);
  //     };

  //     const eventListener = (event) => {
  //       if (event.data?.type === chatbotPreviewMessages.setPreviewSettings) {
  //         // dispatch(setPopupSettingsState(event.data.payload?.type, event.data.payload?.data));
  //         setPreviewSettings(event.data.payload);
  //         setInitDone(true);
  //       }
  //     };

  //     window.addEventListener("message", eventListener);

  //     return () => {
  //       window.removeEventListener("message", eventListener);
  //     };
  //   }, [dispatch]);
  useEffect(() => {
    // if (!initDone) return;
    // dispatch(initPopupSettingsState());
    window._pproject = [];
    _pproject.push(["_project_token", "preview"]);
    _pproject.push(["url_query", ["preview_mode=true"]]);

    const pp = document.createElement("script");
    pp.type = "text/javascript";
    pp.defer = true;
    pp.id = "popup-preview";
    pp.src = `${import.meta.env.VITE_APP_PUBLIC_URL}/iframe/popup.js`;
    const isCurrentOriginLocalhost = window.location.origin.includes("localhost");
    if (isCurrentOriginLocalhost && (import.meta.env.DEV || import.meta.env.VITE_APP_BUILD_ENV === "development")) {
      pp.src = "http://localhost:4000/iframe/popup.js";
      //   if (showPopupPreviewDevMode) {
      //     pp.src = `https://${import.meta.env.VITE_APP_PROXY_URL}/iframe/popup.js`;
      //   }
    }

    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(pp, s);

    // //listen palmate-chatbot event
    // window.addEventListener("palmate-chatbot", (event) => {
    //   //replay event
    //   const type = event?.detail?.type;
    //   if (type === "init") {
    //     const iframe = document.getElementById("_palamar_chat_iframe");

    //     iframe.contentWindow.dashboard ??= {};
    //     iframe.contentWindow.dashboard.reloadPreviewSettings = () => {
    //       const currentPopupSettingsState = selectPopupSettingsState(store.getState());
    //       dispatch(setPopupSettingsState(null, { ...currentPopupSettingsState }));
    //     };
    //   }
    // });

    //force transparent <body> background
    return () => {
      pp.remove();
      const iframe = document.getElementById("_palamar_chat_iframe");
      if (iframe) {
        iframe.remove();
      }
    };
  }, [dispatch, initDone]);

  //   useEffect(() => {
  //     if (window.parent) {
  //       window.parent.postMessage({ type: chatbotPreviewMessages.initLoadDone }, "*");
  //     }
  //   }, []);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "transparent",
        // border: "1px solid black",
      }}
    >
      {/* ChatbotPreview */}
    </Box>
  );
}
