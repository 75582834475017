import { Children, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { withCardon } from "cardon";
import classNames from "classnames";
import { cloneDeep } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdAdd,
  MdCircle,
  MdClose,
  MdCompare,
  MdContentCopy,
  MdDelete,
  MdDone,
  MdDownload,
  MdDragHandle,
  MdPlayCircle,
  MdSave,
} from "react-icons/md";
import { VscClearAll } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Col, Label, Popover, Row } from "reactstrap";

import { Add as AddIcon } from "@mui/icons-material";
import { Box, LinearProgress, Menu, MenuItem, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import CodeEditor from "~components/CodeEditor";
import PRButton from "~components/Generic/PRButton";
import PRDivider from "~components/Generic/PRDivider";
import PRDropZone from "~components/Generic/PRDropZone";
import PRInput from "~components/Generic/PRInput";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import PalIconButton from "~components/mui/PalIconButton";
import PalTooltip from "~components/mui/PalTooltip";
import {
  lowCodeModuleType,
  eCommerceProjectMap,
  lowCodePlatformType,
  lowCodePlatformTypeOptions,
  LS_LOWCODE_CONTEXT,
  LS_LOWCODE_PRESET,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import DialogHelper from "~helpers/DialogHelper";
import FileHelper from "~helpers/FileHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import StorageHelper from "~helpers/StorageHelper";
import Utils from "~helpers/Utils";
import useMonacoCodeCompletion from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoCodeCompletion";
import useMonacoScenarioEditorLowCode from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoScenarioEditorLowCode";
import useMonacoScenarioEditorVariables from "~pages/ChatBot/DialogComponents/ScenarioManager/ScenarioManagerEdit/useMonacoScenarioEditorVariables";
import {
  createOrUpdateProcedure,
  deleteProcedure,
  getProcedureList,
  getProcedurePlatformList,
  getProcedureVersion,
  postDebugSession,
} from "~store/dialogComponents/scenarioManager/actions";
import { getIntellisenseData } from "~store/lowcode/actions";
import { generateAsyncStatisticGenerator, generateStatisticGenerator } from "~store/statisticGenerator/actions";
import { selectMuiTheme } from "~store/theme/selectors";
import { selectCurrentBot, selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import "./style.scss";

const ShellHeader = ({ ...rest }) => {
  return (
    <div {...rest}>
      <span className="color-header1"> palmate@palamar:</span>
      <span className="color-header2">~$</span>
    </div>
  );
};

function OpenNewFileTab({ procedureList = [], onClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const themeJson = useSelector(selectMuiTheme);

  const darkTheme = useMemo(() => {
    const darkTheme = createTheme({
      ...themeJson,
      palette: {
        ...themeJson.palette,
        mode: "dark",
      },
    });
    return darkTheme;
  }, [themeJson]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const nonDisabledProcedures = procedureList
    .filter((item) => !item.disabled && item.id !== "new")
    .map((item) => {
      const lastVersion = item?.all_versions?.reduce((prev, current) => {
        if (prev.version === "base") return current;
        if (current.version === "base") return prev;
        return prev.version > current.version ? prev : current;
      }, item?.all_versions?.[0]);

      return {
        procedure: item,
        last_version_item: lastVersion,
      };
    })
    .sort((a, b) => {
      if (!b?.last_version_item?.created) return -1;
      if (!a?.last_version_item?.created) return 1;

      const dateA = DateHelper.getDateTime(a.last_version_item.created);
      const dateB = DateHelper.getDateTime(b.last_version_item.created);
      return dateB.isAfter(dateA) ? 1 : -1;
    });

  const handleChangeProcedure = (value) => (e) => {
    onClick?.(value);
  };
  const handleClick = (event) => {
    const isShiftClick = event.shiftKey;
    if (isShiftClick) {
      for (const item of nonDisabledProcedures) {
        onClick?.(item.procedure);
      }
      return;
    }

    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Box
          sx={{
            display: nonDisabledProcedures?.length ? "flex" : "none",
            alignItems: "center",
            height: 36,
            ".MuiIconButton-root.MuiIconButton-sizeMedium": {
              height: 28,
              width: 28,
              mx: "4px",
            },
            svg: {
              height: 28,
              color: "white",
            },
          }}
        >
          <PalTooltip title="Open LowCode">
            <PalIconButton onClick={handleClick}>
              <AddIcon />
            </PalIconButton>
          </PalTooltip>
        </Box>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {nonDisabledProcedures.map(({ procedure: item, last_version_item }) => {
            return (
              <MenuItem
                key={item.id}
                sx={{ display: "flex", alignItems: "center" }}
                onClick={handleChangeProcedure(item)}
              >
                {item.name}
                <Typography color="text.secondary" sx={{ ml: 1 }} variant="caption">
                  Saved {DateHelper.getDateTime(last_version_item?.created).fromNow()}
                </Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </ThemeProvider>
    </>
  );
}

function autoGrow(element) {
  const target = element.target;
  target.style.height = "5px";
  target.style.height = target.scrollHeight + "px";
}

function CopyWrapper({ children }) {
  const [showCopy, setShowCopy] = useState(false);
  const [activeShowCopy, setActiveShowCopy] = useState(false);
  const handleShowHideCopy = (status) => () => {
    setShowCopy(status);
  };

  const handleCopy = useCallback(() => {
    if (!navigator.clipboard) return;
    const childrenArray = Children.toArray(children);
    if (childrenArray.some((item) => typeof item !== "string")) return;
    const mergedText = childrenArray.join("");
    navigator.clipboard.writeText(mergedText);
  }, [children]);

  useDebouncedEffect(
    () => {
      setActiveShowCopy(showCopy);
    },
    [showCopy],
    100
  );

  return (
    <div
      className="copy-wrapper d-flex align-items-center"
      onMouseEnter={handleShowHideCopy(true)}
      onMouseLeave={handleShowHideCopy(false)}
    >
      <div className="copy-wrapper-inner">{children}</div>

      <MdContentCopy
        className={classNames("copy-icon ms-1", {
          "opacity-0": !activeShowCopy,
          "text-secondary": true,
        })}
        onClick={handleCopy}
      />
    </div>
  );
}
let id = 0;
const getId = () => ++id;
function LowCodeEditor({
  noFooter,
  get,
  idList,
  onSave,
  onDelete,
  defaultSelectedProcedureName,
  moduleType = lowCodeModuleType.CHATBOT_MODULES,
  customGetProcedureList,
  customCreateOrUpdateProcedure,
  customDeleteProcedure,
  customGetProcedureVersion,
  statisticsReportGeneratorId,
  reservationActionGeneratorId,
  hideContext,
  showSidebarAction,
  enablePlatformSelection,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /** @type {[import("monaco-editor/esm/vs/editor/editor.api").editor.IStandaloneCodeEditor, Function]} */
  const [editor, setEditor] = useState(null);
  /** @type {{ current: typeof import("monaco-editor/esm/vs/editor/editor.api") }} */
  const monacoRef = useRef(null);
  const [keyStr, setKeyStr] = useState("");
  const [valueStr, setValueStr] = useState("");
  const [terminalInputStr, setTerminalInputStr] = useState("");
  const [presetNameStr, setPresetNameStr] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [consoleList, setConsoleList] = useState([]);
  const [contextList, setContextList] = useState([]);
  const [procedureList, setProcedureList] = useState([]);
  const [selectedProcedureName, setSelectedProcedureName] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [presetList, setPresetList] = useState([]);
  const [, setConsoleHistory] = useState({ order: 0, history: [] });
  const [presetAddEnable, setPresetAddEnable] = useState(false);
  const [procedureIdList, setProcedureIdList] = useState(idList);
  const [tabList, setTabList] = useState([]);
  const [platformList, setPlatformList] = useState([]);
  const [tabState, setTabState] = useState({
    current: null,
    previous: null,
  });
  const [isModified, setIsModified] = useState(false);
  const [selectedRightTab, setSelectedRightTab] = useState("lowcode");
  const newItemOption = useMemo(
    () => ({
      id: "new",
      name: (
        <div className="d-flex align-items-center">
          {t("component.lowCodeEditor.newLowCode")}
          <MdAdd className="ms-1 fs-5" />
        </div>
      ),
      implementation: "",
      module_type: undefined,
    }),
    [t]
  );
  const [selectedProcedure, setSelectedProcedure] = useState(newItemOption);
  const [selectedPlatformType, setSelectedPlatformType] = useState(lowCodePlatformType.BOT);
  const [selectedPlatform, setSelectedPlatform] = useState(0);
  const userInfo = useSelector(selectUserInfo);
  const panelRefs = useRef({});

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const lastTerminalMessage = consoleList?.[consoleList.length - 1];
  const { loading: completionLoading } = useMonacoCodeCompletion(editor);

  useEffect(() => {
    if (selectedProcedure.module_type === lowCodeModuleType.PRODUCT_MODULES) {
      setSelectedPlatformType(lowCodePlatformType.PRODUCT);
    } else {
      setSelectedPlatformType(lowCodePlatformType.BOT);
    }
  }, [selectedProcedure]);

  useEffect(() => {
    if (!enablePlatformSelection) return;

    dispatch(getProcedurePlatformList(currentProject.id)).then((response) => {
      setPlatformList(response.results);
    });
  }, [dispatch, currentProject.id, enablePlatformSelection]);

  const handleChangePlatformType = (value) => {
    setSelectedPlatformType(value);
  };
  const handleChangePlatform = (value) => {
    setSelectedPlatform(value);
  };

  const handlePanelRef = useCallback(
    (key) => (ref) => {
      panelRefs.current[key] = ref;
    },
    []
  );

  const togglePanel = useCallback(
    (key) => (e) => {
      //return if not double click
      if (e?.detail !== 2) return;
      const panel = panelRefs.current[key];
      if (!panel.getCollapsed()) {
        panel.collapse();
      } else {
        panel.expand();
      }
    },
    []
  );
  const activeTab = useMemo(() => {
    const previousTab = tabList.find((item) => item.id === tabState.previous);

    const newPreviousTab = {
      ...previousTab,
      viewState: editor?.saveViewState(),
    };
    setTabList((prev) => [...prev.map((item) => (item.id === tabState.previous ? newPreviousTab : item))]);

    const tab = tabList.find((item) => item.id === tabState.current);

    if (previousTab?.diff !== tab?.diff) {
      // set editor null if tab changed to prevent early-execution of hooks based on editor.
      setEditor(null);
    }
    setSelectedProcedureName(tab?.procedure?.name);
    return tab;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState]);

  const handleAddRemoveIdList = useCallback(
    (id, isRemove = false) => {
      let newList = procedureIdList && [...procedureIdList];
      if (procedureIdList) {
        if (isRemove) {
          newList = newList.filter((item) => item !== id);
        } else {
          newList.push(id);
        }
      }
      setProcedureIdList(newList);
      return newList;
    },
    [procedureIdList]
  );

  const { selectedProcedureVersions, selectedProcedureVersionsExceptBase } = useMemo(() => {
    if (!selectedProcedure?.all_versions?.length) return [];
    const sorted = cloneDeep(selectedProcedure?.all_versions);
    sorted.sort((a, b) => b.version - a.version);

    return {
      selectedProcedureVersions: sorted,
      selectedProcedureVersionsExceptBase: sorted.filter((item) => item.version !== "base"),
    };
  }, [selectedProcedure]);
  const handleFocusInput = () => {
    const dom = document.getElementById("terminal-input");
    setShowPopover(false);
    dom.focus();
  };
  const handleFocusContextKey = () => {
    const dom = document.getElementById("terminal-context-key");
    dom.focus();
  };
  const handleFocusContextValue = () => {
    const dom = document.getElementById("terminal-context-value");
    dom.focus();
  };

  const handleChangeProcedure = useCallback(
    async (value) => {
      setSelectedProcedure(value);
      // const newModel = monacoRef.current.editor.createModel(value.implementation, "python");

      const newTab = {
        id: getId(),
        procedure: value,
        // model: newModel,
        viewState: null,
        isModified: false,
        editorValue: value.implementation,
      };
      if (value.id === "new") {
        setSessionId(null);
        let newLowCodeCount = 0;
        while (
          tabList.find(
            (item) => item.procedure?.name === (newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`)
          ) ||
          procedureList.find(
            (item) =>
              item.id !== "new" && item.name === (newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`)
          )
        ) {
          newLowCodeCount++;
        }

        const newProcedureName = newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`;

        newTab.procedure = { ...newItemOption, name: newProcedureName };
      }

      //Always encode the model uri path
      const modelPath = encodeURI(`file:///lowcode/${newTab.procedure.name}`);
      //check if model already exists
      const existingTab = monacoRef.current.editor.getModels().find((item) => item.uri.toString() === modelPath);
      if (existingTab) {
        existingTab?.dispose?.();
      }
      newTab.model = monacoRef.current.editor.createModel(
        newTab.procedure.implementation,
        "python",
        monacoRef.current.Uri.parse(modelPath)
      );
      setTabList((prev) => [...prev, newTab]);
      // setSelectedTab(newTab.id);
      setTabState((prev) => ({ ...prev, previous: prev.current, current: newTab.id }));
      setSelectedProcedureName(newTab?.procedure?.name);
    },
    [tabList]
  );

  useEffect(() => {
    if (!editor) return;
    if (!tabList?.length) {
      handleChangeProcedure({ ...newItemOption, name: "New LowCode 1" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  useEffect(() => {
    if (!editor) return;
    if (defaultSelectedProcedureName) {
      const matchedProcedure = procedureList.find((item) => item.name === defaultSelectedProcedureName);
      if (matchedProcedure) {
        setTabList([]);
        handleChangeProcedure(matchedProcedure);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, procedureList?.length > 0, defaultSelectedProcedureName]);

  useEffect(() => {
    const currentTitle = document.title;
    let newTitle = t("component.lowCodeEditor.title");
    if (currentProject?.name) {
      newTitle += ` - ${currentProject?.name}`;
    }
    document.title = newTitle;
    return () => {
      document.title = currentTitle;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, t]);

  useEffect(() => {
    if (!editor) return;

    if (activeTab) {
      const modifiedModel = activeTab.model;

      const editorType = editor.getEditorType();
      const originalModel = activeTab.originalModel;
      try {
        if (editorType === "vs.editor.IDiffEditor") {
          editor.setModel({ original: originalModel, modified: modifiedModel });
        } else {
          editor.setModel(modifiedModel);
        }
        if (activeTab.viewState) {
          editor.restoreViewState(activeTab.viewState);
        }
      } catch (error) {
        console.error("Model switch error", error);
      }
      setSelectedProcedure(activeTab.procedure);
    }
  }, [activeTab, editor]);

  useEffect(() => {
    dispatch(getIntellisenseData(currentProject.id));
  }, [dispatch, currentProject.id]);

  useEffect(() => {
    // if (typeof selectedProcedure?.name === "string") {
    //   setSelectedProcedureName(selectedProcedure?.name);
    // } else {
    //   setSelectedProcedureName("New LowCode");
    // }
  }, [selectedProcedure, editor]);

  useEffect(() => {
    setTimeout(() => {
      const dom = document.getElementById("terminal-focus");
      dom?.scrollIntoView({ behavior: "smooth" });
    }, 10);
  }, [consoleList]);

  useEffect(() => {
    if (!showPopover) {
      setPresetAddEnable(false);
    }
  }, [showPopover]);

  useEffect(() => {
    (async () => {
      const contextList = await StorageHelper.get(LS_LOWCODE_CONTEXT);
      if (Array.isArray(contextList)) {
        setContextList(contextList);
      }

      const presetList = await StorageHelper.get(LS_LOWCODE_PRESET);
      if (Array.isArray(presetList)) {
        setPresetList([...presetList]);
      }
    })();
  }, []);

  const fetchProcedureList = useCallback(
    (currentProject, currentBot, autoSelect = true, idList) => {
      const filterIdList = idList || procedureIdList;

      let getProcedurePromise;

      const requestParams = [
        currentProject.id,
        currentBot.id,
        {
          id__in: filterIdList,
          limit: 9999,
          module_type:
            enablePlatformSelection && selectedPlatformType === lowCodePlatformType.PRODUCT
              ? lowCodeModuleType.PRODUCT_MODULES
              : moduleType,
          ...(statisticsReportGeneratorId && {
            statistics_report_generators__id: statisticsReportGeneratorId,
          }),
          ...(reservationActionGeneratorId && {
            reservation_action_generators__id: reservationActionGeneratorId,
          }),
          ...(enablePlatformSelection &&
            selectedPlatformType === lowCodePlatformType.PRODUCT && {
              platform_integrations__id: selectedPlatform,
            }),
        },
      ];
      if (customGetProcedureList) {
        getProcedurePromise = customGetProcedureList(...requestParams);
      } else {
        getProcedurePromise = dispatch(getProcedureList(...requestParams));
      }

      getProcedurePromise.then((response) => {
        let { results = [] } = response || {};
        results = results.filter((item) => !filterIdList || filterIdList.includes(item.id));
        setProcedureList([newItemOption, ...results]);
        if (autoSelect) {
          const matchedProcedure = !!results?.length && results.find((item) => item.id === selectedProcedure?.id);
          if (matchedProcedure) {
            setSelectedProcedure(matchedProcedure);
          } else if (results?.length) {
            setSelectedProcedure(results[0]);
          }
        }
      });
    },
    [
      dispatch,
      selectedProcedure,
      moduleType,
      procedureIdList,
      selectedPlatformType,
      selectedPlatform,
      enablePlatformSelection,
    ]
  );

  useEffect(() => {
    if (enablePlatformSelection && selectedPlatformType === lowCodePlatformType.PRODUCT && !selectedPlatform) {
      return;
    }
    fetchProcedureList(currentProject, currentBot, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject, currentBot, selectedPlatformType, selectedPlatform, enablePlatformSelection]);

  const handleKeyDownInput = async (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      const value = e.target.value;

      if (lastTerminalMessage?.action_type === "INPUT_REQUEST" && lastTerminalMessage.answer === undefined) {
        setConsoleList((prev) => [
          ...prev.map((item) => (item === lastTerminalMessage ? { ...item, answer: value } : item)),
        ]);
      } else {
        setConsoleList((prev) => [...prev, { action_type: "INPUT", msg: value }]);
      }

      if (value) {
        setConsoleHistory((prev) => {
          const noDuplicateHistory = new Set([value, ...prev.history]);
          return { ...prev, history: [...noDuplicateHistory], order: 0 };
        });
      }
      const lastConsoleMsg = consoleList?.[consoleList.length - 1];
      const isNumberOrFloat = /^\d+(\.\d+)?$/.test(value);

      const payload = {
        ...(lastConsoleMsg?.action_type === "INPUT_REQUEST" && lastConsoleMsg.answer === undefined
          ? {
              action: "input",
              input: isNumberOrFloat ? Number(value) : value,
            }
          : {
              action: "start",
              command: value,
            }),
        ...(sessionId && { session: sessionId }),
      };
      for (const contextItem of contextList) {
        payload[contextItem.key] = contextItem.value;
      }

      setTerminalInputStr("");

      setShowPopover(false);

      const statisticsReportCommands = [/sync_run\(\)/];

      try {
        if (
          statisticsReportGeneratorId &&
          !(lastConsoleMsg?.action_type === "INPUT_REQUEST" && lastConsoleMsg.answer === undefined) &&
          statisticsReportCommands.some((item) => item.test(value))
        ) {
          if (/sync_run\(\)/.test(value)) {
            const response = await dispatch(generateStatisticGenerator(currentProject.id, statisticsReportGeneratorId));
            setConsoleList((prev) => [...prev, ...response.activities]);
          }
        } else {
          const response = await dispatch(postDebugSession(currentProject.id, currentBot.id, payload));
          const activities = response?.activities;
          setSessionId(response?.id);
          setConsoleList((prev) => [...prev, ...activities]);
        }
      } catch {}
    } else if (e.ctrlKey && e.keyCode === 32) {
      setShowPopover(true);
    } else if (e.key === "Escape") {
      setShowPopover(false);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setConsoleHistory((prev) => {
        const { history, order } = prev;
        if (order < history.length - 1) {
          setTerminalInputStr(history[order + 1]);
          return { ...prev, order: order + 1 };
        }
        return prev;
      });
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setConsoleHistory((prev) => {
        const { history, order } = prev;
        if (order > 0) {
          setTerminalInputStr(history[order - 1]);
          return { ...prev, order: order - 1 };
        }
        return prev;
      });
    }
  };

  const handleChangeInput = (setter) => (e) => {
    setter(e.target.value);
  };
  const handleClickAddContext = () => {
    const newContextList = [...contextList, { key: keyStr, value: valueStr }];
    setContextList(newContextList);
    StorageHelper.set(LS_LOWCODE_CONTEXT, newContextList);
    setKeyStr("");
    setValueStr("");
  };

  const deleteContext = (index) => () => {
    const newList = [...contextList];
    newList.splice(index, 1);
    setContextList(newList);

    StorageHelper.set(LS_LOWCODE_CONTEXT, newList);
  };

  const handleKeyDownKey = (e) => {
    if (e.key === "Enter") {
      handleFocusContextValue();
    }
  };
  const handleKeyDownValue = (e) => {
    if (e.key === "Enter") {
      handleClickAddContext();
      handleFocusContextKey();
    }
  };
  const handleClickClearContext = () => {
    setContextList([]);
    StorageHelper.set(LS_LOWCODE_CONTEXT, []);
  };
  const handleClickClearConsole = () => {
    setConsoleList([]);
  };

  const handleChangeSelectedProcedureName = useCallback(
    (e) => {
      let value = e.target.value;
      if (!value) {
        let newLowCodeCount = 0;
        while (
          tabList.find(
            (item) => item.procedure?.name === (newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`)
          ) ||
          procedureList.find(
            (item) =>
              item.id !== "new" && item.name === (newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`)
          )
        ) {
          newLowCodeCount++;
        }

        value = newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`;
      }
      setSelectedProcedureName(value);

      if (activeTab) {
        const newActiveTab = {
          ...activeTab,
          procedure: {
            ...activeTab.procedure,
            name: value,
          },
        };

        setTabList((prev) => [...prev.map((item) => (item.id === activeTab.id ? newActiveTab : item))]);
      }
    },
    [activeTab, tabList]
  );

  const handleSaveProcedure = useCallback(async () => {
    if (!editor) return;
    if (selectedProcedureName) {
      const activeModel =
        editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();

      let currentContent = "";
      if (activeModel && !activeModel?.isDisposed()) {
        currentContent = activeModel?.getValue() || "";
      }

      const createArgs = [
        currentProject.id,
        currentBot.id,
        {
          from_version: 0,
          ...(selectedProcedure?.id !== "new" && {
            id: selectedProcedure.id,
            from_version: selectedProcedure.last_version,
          }),
          name: selectedProcedureName,
          implementation: currentContent,
          module_type:
            enablePlatformSelection && selectedPlatformType === lowCodePlatformType.PRODUCT
              ? lowCodeModuleType.PRODUCT_MODULES
              : moduleType,
          ...(statisticsReportGeneratorId && {
            statistics_report_generators: [statisticsReportGeneratorId],
          }),
          ...(reservationActionGeneratorId && {
            reservation_action_generators: [reservationActionGeneratorId],
          }),
          ...(enablePlatformSelection &&
            selectedPlatformType === lowCodePlatformType.PRODUCT && {
              platform_integrations: [selectedPlatform],
            }),
        },
      ];
      let response;
      if (customCreateOrUpdateProcedure) {
        response = await customCreateOrUpdateProcedure(...createArgs);
      } else {
        response = await dispatch(createOrUpdateProcedure(...createArgs));
      }
      setIsModified(true);
      if (response) {
        const newList = handleAddRemoveIdList(response.id);
        fetchProcedureList(currentProject, currentBot, true, newList);
        onSave?.(response);
        //update tabList

        setTabList((prev) =>
          prev.map((item) => {
            //also update diff tabs
            if (item.id === activeTab.id || activeTab.model === item.model) {
              return {
                ...item,
                procedure: response,
                isModified: false,
                editorValue: response.implementation,
                statistics_report_generators: response.statistics_report_generators,
              };
            }
            return item;
          })
        );

        setSelectedProcedure(response);
      }
    } else {
      DialogHelper.showValidate([t("component.lowCodeEditor.procedureNameIsRequired")]);
    }
  }, [
    activeTab,
    handleAddRemoveIdList,
    currentProject,
    currentBot,
    dispatch,
    editor,
    fetchProcedureList,
    selectedProcedure,
    selectedProcedureName,
    statisticsReportGeneratorId,
    reservationActionGeneratorId,
    moduleType,
    t,
    selectedPlatformType,
    selectedPlatform,
    enablePlatformSelection,
  ]);

  const handleDeleteProcedure = async () => {
    const name = selectedProcedure?.name;
    if (!(await DialogHelper.showQuestionYesNo("", t("component.lowCodeEditor.deleteQuestion").format(name)))) return;

    let response;
    if (customDeleteProcedure) {
      response = await customDeleteProcedure(currentProject.id, currentBot.id, selectedProcedure.id);
    } else {
      response = await dispatch(deleteProcedure(currentProject.id, currentBot.id, selectedProcedure.id));
    }

    const newList = handleAddRemoveIdList(response.id, true);

    onDelete?.(selectedProcedure);
    fetchProcedureList(currentProject, currentBot, true, newList);
    handleCloseTab(activeTab.id, true);
  };

  const handleMountEditor = useCallback(
    /**
     * @type {({
     *   editor: import("monaco-editor").editor.IStandaloneCodeEditor,
     *   monaco: typeof import("monaco-editor")}
     * ) => void}
     */
    ({ editor, monaco }) => {
      setEditor(editor);
      monacoRef.current = monaco;
    },
    []
  );
  const handleSelectTab = useCallback((id) => {
    // setSelectedTab(id);
    setTabState((prev) => ({ ...prev, previous: prev.current, current: id }));
  }, []);

  const handleCloseTab = useCallback(
    async (id, force = false) => {
      const targetTab = tabList.find((item) => item.id === id);
      if (force !== true && targetTab.isModified) {
        if (!(await DialogHelper.showQuestionYesNo("", t("component.lowCodeEditor.closeTabQuestion")))) {
          return;
        }
      }
      const toBeDeletedTab = tabList.find((item) => item.id === id);
      const newTabList = tabList.filter((item) => item.id !== id);
      if (activeTab.id === id) {
        const nextTab = newTabList?.[newTabList.length - 1]?.id;
        if (nextTab) {
          handleSelectTab(nextTab);
        } else {
          handleChangeProcedure(newItemOption);
        }
        // setSelectedTab(newTabList?.[newTabList.length - 1]?.id);
        // editor.setModel(newTabList?.[newTabList.length - 1]?.model);
        // editor.restoreViewState(newTabList?.[newTabList.length - 1]?.viewState);
      }

      if (
        toBeDeletedTab.model &&
        !newTabList.find((item) => item?.model === toBeDeletedTab.model || item?.originalModel === toBeDeletedTab.model)
      ) {
        toBeDeletedTab.model?.dispose?.();
      }
      if (
        toBeDeletedTab.originalModel &&
        !newTabList.find(
          (item) => item?.modal === toBeDeletedTab.originalModel || item?.originalModel === toBeDeletedTab.originalModel
        )
      ) {
        toBeDeletedTab.originalModel?.dispose?.();
      }
      if (!newTabList?.length) {
        const id = getId();
        let newLowCodeCount = 0;
        // newTabList.filter((item) => item.procedure?.name?.startsWith("New LowCode"))?.length || 0;
        while (
          newTabList.find(
            (item) => item.procedure?.name === (newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`)
          ) ||
          procedureList.find(
            (item) =>
              item.id !== "new" && item.name === (newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`)
          )
        ) {
          newLowCodeCount++;
        }

        const lowcodeFileName = newLowCodeCount ? `New LowCode ${newLowCodeCount}` : `New LowCode`;
        const modelPath = encodeURI(`file:///lowcode/${Utils.slugify(lowcodeFileName)}.py`);
        newTabList.push({
          id,
          procedure: {
            ...newItemOption,
            name: lowcodeFileName,
          },
          model: monacoRef.current.editor.createModel(
            newItemOption.implementation,
            "python",
            monacoRef.current.Uri.parse(modelPath)
          ),
          viewState: null,
          isModified: false,
          editorValue: newItemOption.implementation,
        });
        handleSelectTab(newTabList[0].id);
      }
      setTabList(newTabList);
    },
    [handleSelectTab, activeTab, tabList, handleChangeProcedure, t]
  );

  useEffect(() => {
    if (!editor) return;
    const disposeList = [];
    disposeList.push(
      editor.addAction({
        id: "save-low-code",
        label: t("component.lowCodeEditor.save"),
        contextMenuGroupId: "edit",
        keybindings: [monacoRef.current.KeyMod.CtrlCmd | monacoRef.current.KeyCode.KeyS],
        run: () => {
          handleSaveProcedure();
        },
      })
    );
    disposeList.push(
      editor.addAction({
        id: "close-tab",
        label: t("component.lowCodeEditor.closeTab"),
        contextMenuGroupId: "navigation",
        keybindings: [monacoRef.current.KeyMod.Alt | monacoRef.current.KeyCode.KeyW],
        run: () => {
          handleCloseTab(activeTab.id);
        },
      })
    );

    //also add global hotkeys
    const handleGlobalHotkeys = (e) => {
      //alt + w
      if (e.altKey && e.keyCode === 87) {
        e.preventDefault();
        handleCloseTab(activeTab.id);
      }
      //(ctrl or cmd) + s
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 83) {
        e.preventDefault();
        handleSaveProcedure();
      }
    };

    const beforeUnloadHandler = async (e) => {
      const hasUnsavedTab = tabList.some((item) => item.isModified);
      if (hasUnsavedTab) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);
    disposeList.push({
      dispose: () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      },
    });

    document.addEventListener("keydown", handleGlobalHotkeys);
    disposeList.push({
      dispose: () => {
        document.removeEventListener("keydown", handleGlobalHotkeys);
      },
    });

    return () => {
      disposeList.forEach((item) => item.dispose());
    };
  }, [editor, handleSaveProcedure, monacoRef, handleCloseTab, activeTab, tabList, t]);

  const togglePopover = () => {
    setShowPopover(false);
  };
  useEffect(() => {
    //disable go back action
    const handleBackButton = (e) => {
      e.preventDefault();
      window.history.go(1);
    };

    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const filteredPresetList = useMemo(() => {
    if (!terminalInputStr) return presetList;
    return presetList.filter(
      (item) => item.placeholder || item.name.toLowerCase().includes(terminalInputStr.toLowerCase())
    );
  }, [presetList, terminalInputStr]);

  const togglePresetAdd = (status) => () => {
    setPresetAddEnable(status);
  };

  const handleKeyDownSavePreset = (e) => {
    if (e.key === "Enter") {
      handleClickSavePreset();
    }
  };

  const handleClickSavePreset = async () => {
    if (presetNameStr) {
      setPresetList((prev) => {
        const newState = [
          ...prev,
          {
            placeholder: false,
            name: presetNameStr,
            value: terminalInputStr,
          },
        ];
        StorageHelper.set(LS_LOWCODE_PRESET, newState);
        return newState;
      });
      setPresetNameStr("");
      setPresetAddEnable(false);
      setShowPopover(false);
    } else {
      DialogHelper.showValidate([t("component.lowCodeEditor.presetNameIsRequired")]);
    }
  };

  const handleClickDeletePreset = (index) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setPresetList((prev) => {
      const newState = [...prev];
      newState.splice(index, 1);
      StorageHelper.set(LS_LOWCODE_PRESET, newState);
      return newState;
    });
  };

  const handlePreventPropagation = (e) => {
    e.stopPropagation();
  };
  const handleClickApplyPreset = (preset) => () => {
    setTerminalInputStr(preset.value);
    setShowPopover(false);
    handleFocusInput();
  };

  const handleChangeCodeEditorValue = (value) => {
    if (activeTab) {
      const modifiedTabs = tabList.filter((a) => a.model === activeTab.model);
      const isModified = activeTab.procedure?.implementation !== value;

      // const newActiveTab = {
      //   ...activeTab,
      //   isModified: activeTab.procedure?.implementation !== value,
      //   editorValue: value,
      // };
      setTabList((prev) => {
        const newList = [];
        for (const tab of prev) {
          const matchedTab = modifiedTabs.find((item) => item.id === tab.id);
          if (matchedTab) {
            newList.push({
              ...tab,
              isModified,
              editorValue: value,
            });
          } else {
            newList.push(tab);
          }
        }
        return newList;
        // [...prev.map((item) => (item.id === activeTab.id ? newActiveTab : item))];
      });
    }
  };

  const closeIconRenderer = useCallback(
    (tab) => {
      const targetTab = tabList.find((item) => item.id === tab.id);
      if (targetTab?.isModified) {
        return MdCircle;
      }
      return MdClose;
    },
    [tabList]
  );

  const procedureListOptions = useMemo(() => {
    if (!procedureList?.length) return [];
    return procedureList.map((item) => ({
      ...item,
      disabled: tabList.some((tab) => tab.procedure?.id !== "new" && tab.procedure?.id === item.id),
    }));
  }, [procedureList, tabList]);

  const handleChangeRightTab = (id) => {
    setSelectedRightTab(id);
  };

  const { latestVersion, baseVersion } = useMemo(() => {
    if (!selectedProcedure?.all_versions?.length) return 0;
    const sorted = cloneDeep(selectedProcedure?.all_versions);
    sorted.sort((a, b) => b.version - a.version);

    const latestVersion = sorted[0].version;
    const baseVersion = sorted.find((item) => item.version === "base")?.version;
    return {
      latestVersion,
      baseVersion,
    };
  }, [selectedProcedure]);

  const handleClickCompareByVersion = useCallback(
    (version) => async () => {
      const row = selectedProcedureVersions.find((item) => item.version === version);
      if (!row) return;

      const alreadyExistTab = tabList.find((item) => item.compareVersion === version && item.diff);
      if (alreadyExistTab) {
        if (alreadyExistTab.id === tabState.current) return;

        setTabState((prev) => ({ ...prev, previous: prev.current, current: alreadyExistTab.id }));
        return;
      }

      let versionSource;
      if (version === "base") {
        versionSource = row.source;
      } else {
        let versionResponse;

        if (customGetProcedureVersion) {
          versionResponse = await customGetProcedureVersion(
            currentProject.id,
            currentBot.id,
            selectedProcedure.id,
            row.id
          );
        } else {
          versionResponse = await dispatch(
            getProcedureVersion(currentProject.id, currentBot.id, selectedProcedure.id, row.id)
          );
        }
        versionSource = versionResponse.source;
      }

      const originalModel = monacoRef.current.editor.createModel(versionSource, "python");
      const activeTab = tabList.find((item) => item.id === tabState.current);
      const versionStr = version === "base" ? "Base" : "v" + version;
      const newTab = {
        ...activeTab,
        id: getId(),
        procedure: {
          ...activeTab.procedure,
        },
        tabSuffix: ` (${versionStr}) ⇆ ${activeTab.procedure.name}`,
        // <span className="ps-1 color-dark">
        //   (Compare with<div className="d-inline ms-1 text-danger">{versionStr}</div>)
        // </span>
        compareVersion: version,
        diff: true,
        originalModel: originalModel,
        originalEditorValue: versionSource,
      };

      setSessionId(null);
      setTabList((prev) => [...prev, newTab]);
      setTabState((prev) => ({ ...prev, previous: prev.current, current: newTab.id }));
    },
    [currentBot.id, currentProject.id, dispatch, selectedProcedure.id, selectedProcedureVersions, tabList, tabState]
  );

  const historyColumns = useMemo(() => {
    return [
      {
        key: "version",
        label: "V",
        render: (item) => (`v${item.version}` || "").toString(),
      },
      {
        key: "created",
        label: t("common.created"),
        render: (item) => (
          <div>
            <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
            </PRTooltip>
          </div>
        ),
      },
      {
        key: "changed_by",
        label: "Changed By",
        render: (item) => item.changed_by || "-",
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => {
          const handleClickCompare = async () => {
            handleClickCompareByVersion(row.version)();
          };
          const handleClickApply = async () => {
            let versionResponse;

            if (customGetProcedureVersion) {
              versionResponse = await customGetProcedureVersion(
                currentProject.id,
                currentBot.id,
                selectedProcedure.id,
                row.id
              );
            } else {
              versionResponse = await dispatch(
                getProcedureVersion(currentProject.id, currentBot.id, selectedProcedure.id, row.id)
              );
            }
            activeTab.model.pushEditOperations(
              [], // beforeCursorState
              [
                {
                  range: activeTab.model.getFullModelRange(),
                  text: versionResponse.source,
                  forceMoveMarkers: true,
                },
              ],
              () => null // computeCursorState
            );
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                color="primary"
                icon={MdCompare}
                size="sm"
                tooltipText={t("component.lowCodeEditor.compare")}
                onClick={handleClickCompare}
              />
              <PRButton
                outline
                className="ms-1"
                color="success"
                icon={MdDone}
                size="sm"
                tooltipText={t("common.apply")}
                onClick={handleClickApply}
                // disabled={activeTab?.diff}
              />
            </div>
          );
        },
      },
    ];
  }, [currentBot.id, currentProject.id, dispatch, selectedProcedure.id, activeTab, handleClickCompareByVersion, t]);

  const downloadAllLowCode = async () => {
    let response;

    const requestParams = [
      currentProject.id,
      currentBot.id,
      {
        limit: 9999,
        module_type:
          enablePlatformSelection && selectedPlatformType === lowCodePlatformType.PRODUCT
            ? lowCodeModuleType.PRODUCT_MODULES
            : moduleType,
        ...(statisticsReportGeneratorId && {
          statistics_report_generators__id: statisticsReportGeneratorId,
        }),
        ...(reservationActionGeneratorId && {
          reservation_action_generators__id: reservationActionGeneratorId,
        }),
        ...(enablePlatformSelection &&
          selectedPlatformType === lowCodePlatformType.PRODUCT && {
            platform_integrations__id: selectedPlatform,
          }),
      },
    ];
    if (customGetProcedureList) {
      response = await customGetProcedureList(...requestParams);
    } else {
      response = await dispatch(getProcedureList(...requestParams));
    }

    const { results = [] } = response || {};
    const jszip = await FileHelper.getJSZip();
    const zip = new jszip();
    LoadingHelper.open();
    results.forEach((item) => {
      zip.file(`${item.name}.py`, item.implementation);
    });
    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        FileHelper.saveAs(content, `${Utils.slugify(currentProject.name)}_lowcode.zip`, "application/zip");
      })
      .finally(() => {
        LoadingHelper.close();
      });
  };

  const uploadAllLowCode = async (files) => {
    if (files.length) {
      const jszip = await FileHelper.getJSZip();
      const zip = new jszip();
      LoadingHelper.open();
      zip
        .loadAsync(files[0])
        .then(async (zip) => {
          const fileList = Object.keys(zip.files);

          const promiseList = [];
          let unmatchedFileCount = 0;
          let modifiedFileCount = 0;
          for (const fileName of fileList) {
            const file = zip.files[fileName];
            const content = await file.async("text");

            const relatedProcedure = procedureList.find((item) => item.name === fileName.replace(".py", ""));
            if (relatedProcedure) {
              if (relatedProcedure.implementation !== content) {
                const createArgs = [
                  currentProject.id,
                  currentBot.id,
                  {
                    id: relatedProcedure.id,
                    from_version: relatedProcedure.last_version,
                    name: relatedProcedure.name,
                    implementation: content,
                    ...(statisticsReportGeneratorId && {
                      statistics_report_generators: [statisticsReportGeneratorId],
                    }),
                    ...(reservationActionGeneratorId && {
                      reservation_action_generators: [reservationActionGeneratorId],
                    }),
                    ...(enablePlatformSelection &&
                      selectedPlatformType === lowCodePlatformType.PRODUCT && {
                        platform_integrations: [selectedPlatform],
                      }),
                  },
                  {
                    loading: false,
                    successMsg: false,
                  },
                ];

                let promiseItem;
                if (customCreateOrUpdateProcedure) {
                  promiseItem = customCreateOrUpdateProcedure(...createArgs);
                } else {
                  promiseItem = dispatch(createOrUpdateProcedure(...createArgs));
                }
                promiseList.push(promiseItem);
                modifiedFileCount++;
                console.log("LowCode updated: ", fileName);
              }
            } else {
              unmatchedFileCount++;
            }
          }
          const response = await Promise.all(promiseList);
          if (response) {
            fetchProcedureList(currentProject, currentBot, true);
          }

          AlertHelper.showSuccess(
            t("component.lowCodeEditor.uploadSuccess").format(modifiedFileCount, fileList.length, unmatchedFileCount)
          );
        })
        .finally(() => {
          LoadingHelper.close();
        });
    }
  };

  const OpenNewFileComp = useMemo(() => {
    return <OpenNewFileTab procedureList={procedureListOptions} onClick={handleChangeProcedure} />;
  }, [handleChangeProcedure, procedureListOptions]);

  const editorTabList = useMemo(() => {
    return [
      ...tabList,
      {
        nonClickable: true,
        id: "search",
        render: () => {
          return OpenNewFileComp;
        },
      },
    ];
  }, [tabList, OpenNewFileComp]);

  useMonacoScenarioEditorVariables(editor);
  useMonacoScenarioEditorLowCode(editor);

  const handleClickExecuteLowCode = async () => {
    const mainProcedure = procedureList.find((item) => item.name === "main" && item.id && item.id !== "new");
    if (!mainProcedure) {
      DialogHelper.showOk(t("component.lowCodeEditor.mainProcedureNotFound"));
      return;
    }

    setConsoleList((prev) => [...prev, { action_type: "WARN", msg: "Executing..." }]);
    await dispatch(generateAsyncStatisticGenerator(currentProject.id, statisticsReportGeneratorId));
    // const activities = debugResult?.activities;
    // setSessionId(debugResult?.id);
    // setConsoleList((prev) => [...prev, ...activities]);
  };

  const handleCloseModal = () => {
    for (const tab of tabList) {
      tab.model?.dispose?.();
      tab.originalModel?.dispose?.();
    }
    get(isModified || false)();
  };
  return (
    <PRModal
      dark
      fullscreen
      className="pr-low-code-editor"
      noFooter={noFooter}
      submitText=""
      title={`${t("component.lowCodeEditor.title")} - ${currentProject?.name}`}
      onClose={handleCloseModal}
    >
      <LinearProgress
        sx={{
          visibility: completionLoading ? "visible" : "hidden",
          mb: 0.5,
        }}
      />
      <PanelGroup autoSaveId="low-code-editor-panel" direction="vertical">
        <Panel minSize={40} order={1}>
          <PanelGroup autoSaveId="low-code-editor-panel-2" className="editor" direction="horizontal">
            <Panel defaultSize={75} minSize={25} order={1}>
              <Row className="flex-column h-100 overflow-hidden">
                <Col xs="auto">
                  <PRTab
                    // labelSelector="procedure.name"
                    closeIconRenderer={closeIconRenderer}
                    labelRenderer={(tab) =>
                      tab?.tabSuffix ? (
                        <>
                          {tab.procedure?.name}
                          {tab?.tabSuffix}
                        </>
                      ) : (
                        tab.procedure?.name
                      )
                    }
                    tab={tabState.current}
                    tabList={editorTabList}
                    onChange={handleSelectTab}
                    onClose={handleCloseTab}
                  />
                </Col>
                <Col xs className="overflow-hidden">
                  <CodeEditor
                    noBorder
                    noResizable
                    noToolbar
                    scrollBeyondLastLine
                    autoRestoreModelAndState={false}
                    defaultHeight="100%"
                    defaultLanguage="python"
                    diff={activeTab?.diff}
                    language={"python"}
                    theme="vs-dark"
                    onChange={handleChangeCodeEditorValue}
                    onMount={handleMountEditor}
                  />
                </Col>
              </Row>
            </Panel>
            <PanelResizeHandle>
              <div className="panel-resize-handle" onClick={togglePanel("rightPanel")}>
                <MdDragHandle className="rotate-90" />
              </div>
            </PanelResizeHandle>
            <Panel ref={handlePanelRef("rightPanel")} collapsible defaultSize={25} minSize={15} order={2}>
              <PanelGroup autoSaveId="low-code-editor-panel-3" direction="vertical">
                <Panel className="context" defaultSize={80} minSize={25} order={1}>
                  {showSidebarAction && (
                    <>
                      <PRButton
                        className="w-100 mb-2"
                        color="success"
                        icon={MdPlayCircle}
                        onClick={handleClickExecuteLowCode}
                      >
                        {t("component.lowCodeEditor.execute")}
                      </PRButton>
                    </>
                  )}
                  <PRTab
                    className="mb-2"
                    tab={selectedRightTab}
                    tabList={[
                      {
                        id: "lowcode",
                        label: t("common.lowCode"),
                      },
                      {
                        id: "history",
                        label: `${t("common.history")}${
                          selectedProcedureVersionsExceptBase?.length
                            ? ` (${selectedProcedureVersionsExceptBase?.length})`
                            : ""
                        }`,
                      },
                      userInfo.is_superuser && {
                        id: "export-import",
                        label: t("component.lowCodeEditor.exportImport"),
                      },
                    ].filter(Boolean)}
                    onChange={handleChangeRightTab}
                  />
                  <div
                    style={{
                      overflowY: "auto",
                      padding: 10,
                      height: "calc(100% - 40px)",
                    }}
                  >
                    {selectedRightTab === "lowcode" && (
                      <>
                        {enablePlatformSelection && (
                          <>
                            <Label className="mt-3">{t("component.lowCodeEditor.platform")}</Label>
                            <PRSelect
                              isPrimitiveValue
                              menuPortal
                              className="pr-select-dark"
                              classNamePortal="pr-select-dark"
                              isClearable={false}
                              options={lowCodePlatformTypeOptions}
                              value={selectedPlatformType}
                              onChange={handleChangePlatformType}
                            />
                            {selectedPlatformType === lowCodePlatformType.PRODUCT && (
                              <>
                                <Label className="mt-3">{t("component.lowCodeEditor.product")}</Label>
                                <PRSelect
                                  isPrimitiveValue
                                  menuPortal
                                  className="pr-select-dark"
                                  classNamePortal="pr-select-dark"
                                  isClearable={false}
                                  labelRenderer={(item) =>
                                    eCommerceProjectMap[item.platform_type] || item.platform_type
                                  }
                                  labelSelector="platform_type"
                                  options={platformList}
                                  value={selectedPlatform}
                                  valueSelector="id"
                                  onChange={handleChangePlatform}
                                />
                              </>
                            )}

                            <Label className="mt-3">{t("component.lowCodeEditor.lowCode")}</Label>
                          </>
                        )}
                        <PRSelect
                          menuPortal
                          className="pr-select-dark"
                          classNamePortal="pr-select-dark"
                          isClearable={false}
                          labelSelector="name"
                          options={procedureListOptions}
                          value={selectedProcedure}
                          valueSelector="id"
                          onChange={handleChangeProcedure}
                        />
                        <Label className="mt-3">{t("common.name")}</Label>
                        <PRInput
                          id="new-name"
                          value={selectedProcedureName}
                          onChange={handleChangeSelectedProcedureName}
                        />
                        <Row className="gx-1 justify-content-end mt-1">
                          <Col xs="6">
                            <PRButton
                              className="w-100 "
                              disabled={
                                enablePlatformSelection &&
                                selectedPlatformType === lowCodePlatformType.PRODUCT &&
                                !selectedPlatform
                              }
                              icon={MdSave}
                              size="sm"
                              onClick={handleSaveProcedure}
                            >
                              {t("common.save")}
                            </PRButton>
                          </Col>
                          <Col className="text-truncate" xs="6">
                            <PRButton
                              outline
                              className="w-100"
                              color="danger"
                              disabled={
                                !selectedProcedure?.id ||
                                selectedProcedure.id === "new" ||
                                (enablePlatformSelection &&
                                  selectedPlatformType === lowCodePlatformType.PRODUCT &&
                                  !selectedPlatform)
                              }
                              icon={MdDelete}
                              size="sm"
                              onClick={handleDeleteProcedure}
                            >
                              {t("common.delete")}
                            </PRButton>
                          </Col>
                        </Row>
                      </>
                    )}
                    {selectedRightTab === "history" && (
                      <>
                        <Row className="g-2">
                          <Col xs="12">{t("component.lowCodeEditor.compareWith")}:</Col>
                          <Col xs>
                            <PRButton
                              className="w-100 "
                              disabled={!latestVersion}
                              icon={MdCompare}
                              size="sm"
                              onClick={handleClickCompareByVersion(latestVersion)}
                            >
                              {t("component.lowCodeEditor.latest")} (#{latestVersion})
                            </PRButton>
                          </Col>
                          <Col xs>
                            <PRButton
                              className="w-100 "
                              disabled={!baseVersion}
                              icon={MdCompare}
                              size="sm"
                              onClick={handleClickCompareByVersion(baseVersion)}
                            >
                              {t("component.lowCodeEditor.base")}
                            </PRButton>
                          </Col>
                        </Row>
                        <PRTable
                          inline
                          noPagination
                          columns={historyColumns}
                          data={selectedProcedureVersionsExceptBase}
                        />
                      </>
                    )}
                    {selectedRightTab === "export-import" && (
                      <>
                        <Row className="gx-2 gy-3">
                          <Col xs="12">
                            <PRButton className="w-100" icon={MdDownload} onClick={downloadAllLowCode}>
                              {t("component.lowCodeEditor.exportLowCode")}{" "}
                              {procedureList?.length ? `(${procedureList?.length})` : ""}
                            </PRButton>
                          </Col>

                          <Col xs="12">
                            <Label>{t("component.lowCodeEditor.importLowCode")}</Label>
                            <PRDropZone
                              hideSelectedFiles
                              accept={[".zip"]}
                              maxFiles={1}
                              onFileChange={uploadAllLowCode}
                              // className="mt-4"
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Panel>
                {!hideContext && (
                  <>
                    <PanelResizeHandle>
                      <div className="panel-resize-handle">
                        <MdDragHandle className=" " />
                      </div>
                    </PanelResizeHandle>
                    <Panel className="context" defaultSize={20} minSize={20} order={1}>
                      <div style={{ height: 135 }}>
                        <Label>{t("common.context")}</Label>
                        <PRInput
                          on
                          className="mb-1"
                          id="terminal-context-key"
                          placeholder="Key"
                          value={keyStr}
                          onChange={handleChangeInput(setKeyStr)}
                          onKeyDown={handleKeyDownKey}
                        />
                        <PRInput
                          className="mb-1"
                          id="terminal-context-value"
                          placeholder="Value"
                          value={valueStr}
                          onChange={handleChangeInput(setValueStr)}
                          onKeyDown={handleKeyDownValue}
                        />
                        <Row className="gx-1">
                          <Col xs>
                            <PRButton className="w-100" icon={MdAdd} size="sm" onClick={handleClickAddContext}>
                              {t("common.add")}
                            </PRButton>
                          </Col>
                          <Col xs="auto">
                            <PRButton
                              outline
                              className="w-100"
                              color="danger"
                              icon={MdDelete}
                              size="sm"
                              tooltipText={t("component.lowCodeEditor.clearContext")}
                              onClick={handleClickClearContext}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div
                        style={{
                          overflowY: "auto",
                          padding: 10,
                          height: "calc(100% - 100px)",
                        }}
                      >
                        {contextList.map((item, index, arr) => (
                          <div key={index} className="">
                            <Row className="align-items-center">
                              <Col xs>
                                <span>
                                  <span className="">
                                    {item.key} = {item.value}
                                  </span>
                                </span>
                              </Col>
                              <Col xs="auto">
                                <PRButton
                                  onlyIcon
                                  outline
                                  color="danger"
                                  icon={MdDelete}
                                  onClick={deleteContext(index)}
                                />
                              </Col>
                              {arr.length - 1 !== index && (
                                <Col xs="12">
                                  <PRDivider className="my-1" color="secondary" />
                                </Col>
                              )}
                            </Row>
                          </div>
                        ))}
                      </div>
                    </Panel>
                  </>
                )}
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </Panel>
        <PanelResizeHandle>
          <div className="panel-resize-handle" onClick={togglePanel("bottomPanel")}>
            <MdDragHandle className=" " />
          </div>
        </PanelResizeHandle>
        <Panel ref={handlePanelRef("bottomPanel")} collapsible defaultSize={15} minSize={10} order={2}>
          <div className="h-100">
            <div className="terminal-tab">
              <Row className="">
                <Col xs="12">
                  <PRTab tab={1} tabList={[{ id: 1, label: "Terminal" }]} />
                </Col>
              </Row>
              <div className="tab-actions">
                <PRButton
                  noBorder
                  outline
                  color="secondary"
                  icon={VscClearAll}
                  size="sm"
                  tooltipText={t("component.lowCodeEditor.clearConsole")}
                  onClick={handleClickClearConsole}
                />
              </div>
            </div>
            <div className="terminal p-1" id="terminal-console" onClick={handleFocusInput}>
              <Row className="terminal-inline">
                {consoleList.map((item, index, arr) => {
                  const isLastItem = arr.length - 1 === index;

                  if (isLastItem && item?.action_type === "INPUT_REQUEST" && item?.answer === undefined) {
                    return;
                  }
                  const handlePreventClick = (e) => {
                    e.stopPropagation();
                  };

                  return (
                    <Col key={index} xs="12">
                      <span className="color1 d-flex align-items-start">
                        <ShellHeader
                          onClick={handlePreventClick}
                          onMouseDown={handlePreventClick}
                          onMouseUp={handlePreventClick}
                        />
                        <span
                          className={classNames("ms-1", {
                            "color-print": ["PRINT", "INPUT_REQUEST"].includes(item?.action_type),
                            "color-dark": ["LOG"].includes(item?.action_type),
                            "color-output": ["OUTPUT"].includes(item?.action_type),
                            "color-warn": ["WARN"].includes(item?.action_type),
                          })}
                          onClick={handlePreventClick}
                          onMouseDown={handlePreventClick}
                          onMouseUp={handlePreventClick}
                        >
                          <CopyWrapper>
                            {item?.msg}
                            {item?.answer && <span className={"ms-1 color-input"}>{item?.answer}</span>}
                          </CopyWrapper>
                        </span>
                      </span>
                    </Col>
                  );
                })}

                <Col xs="12">
                  <Row className="color1 d-flex align-items-start g-0">
                    <Col className="d-flex align-items-center" xs="auto">
                      <ShellHeader />
                      {lastTerminalMessage?.action_type === "INPUT_REQUEST" &&
                        lastTerminalMessage.answer === undefined && (
                          <span
                            className={classNames("ms-1", {
                              color2: true,
                            })}
                          >
                            {lastTerminalMessage?.msg}
                          </span>
                        )}
                    </Col>
                    <Col xs>
                      <textarea
                        autoComplete="off"
                        className="ms-1 w-100"
                        id="terminal-input"
                        value={terminalInputStr}
                        onChange={handleChangeInput(setTerminalInputStr)}
                        onInput={autoGrow}
                        onKeyDown={handleKeyDownInput}
                      />
                      <Popover
                        hideArrow
                        className="pr-low-code-editor"
                        fade={false}
                        isOpen={showPopover}
                        popperClassName="pr-low-code-editor terminal-popover"
                        target={"terminal-input"}
                        toggle={togglePopover}
                      >
                        <div
                          className={classNames("suggestion-box")}
                          id="terminal-popover"
                          onClick={handlePreventPropagation}
                        >
                          {!filteredPresetList.length && (
                            <>
                              <div className="text-muted ">
                                {!presetAddEnable ? (
                                  <>
                                    <div className="color2">Preset not found</div>
                                    <span className="text-secondary underline" onClick={togglePresetAdd(true)}>
                                      {t("component.lowCodeEditor.createPresetLink")}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="color2">{t("component.lowCodeEditor.createPreset")}</div>
                                    <div className="d-flex align-items-center w-100">
                                      <PRInput
                                        className="preset-input me-1  w-100"
                                        placeholder="Preset Name"
                                        value={presetNameStr}
                                        onChange={handleChangeInput(setPresetNameStr)}
                                        onKeyDown={handleKeyDownSavePreset}
                                      />
                                      <PRButton
                                        outline
                                        color="primary"
                                        icon={MdSave}
                                        size="sm"
                                        onClick={handleClickSavePreset}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </>
                          )}
                          <table>
                            <tbody>
                              {filteredPresetList.map((item, index) => (
                                <tr
                                  key={item.name + index}
                                  className="preset-item"
                                  onClick={handleClickApplyPreset(item)}
                                >
                                  <td className="vertical-top">
                                    <span className="color2 me-2 font-size-12">{item.name}:</span>
                                  </td>
                                  <td className="w-100">
                                    <span className="preset-code color3">{item.value}</span>
                                  </td>
                                  <td className="vertical-top">
                                    <PRButton
                                      onlyIcon
                                      outline
                                      color="secondary"
                                      icon={MdDelete}
                                      size="sm"
                                      onClick={handleClickDeletePreset(item)}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Popover>
                    </Col>
                  </Row>
                </Col>
                <div id="terminal-focus" />
              </Row>
            </div>
          </div>
        </Panel>
      </PanelGroup>
    </PRModal>
  );
}
const LowCodeEditorModal = withCardon(LowCodeEditor, { destroyOnHide: true });
export default LowCodeEditorModal;
