import PRSwitch from "~components/Generic/PRSwitch";

import Assets from "./Assets";
import Channels from "./Channels";
import ECommerce from "./ECommerce";
import GuideDefinition from "./GuideDefinition";
import MessageTemplate from "./MessageTemplate";
import PopupSettings from "./PopupSettings";
import MyProjectSettings from "./ProjectSettings/MyProjectSettings";
import SystemMessageTemplate from "./SystemMessageTemplate";
import User from "./User";
import InvitedUser from "./User/InvitedUser";

export default function Settings() {
  const routes = [
    { path: "/settings/channels", component: Channels },
    { path: "/settings/e-commerce", component: ECommerce },
    { path: "/settings/project", component: MyProjectSettings },
    { path: "/settings/popup", component: PopupSettings },
    { path: "/settings/user", component: User },
    { path: "/settings/invited-user", component: InvitedUser },
    { path: "/settings/message-template", component: MessageTemplate },
    { path: "/settings/assets", component: Assets },
    { path: "/settings/system-message-template", component: SystemMessageTemplate },
    { path: "/settings/guide-definition", component: GuideDefinition },
    { path: "/settings", to: "/settings/project", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}
