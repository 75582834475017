import { memo, useState } from "react";

import chroma from "chroma-js";
import classNames from "classnames";
import _ from "lodash";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";

import { ReactComponent as WebChatCheckboxCheckedIcon } from "~assets/images/icons/webchat-checkbox-checked.svg";
import { ReactComponent as WebChatCheckboxIcon } from "~assets/images/icons/webchat-checkbox.svg";
import PRButton from "~components/Generic/PRButton";
import StyledHelper from "~helpers/StyledHelper";

import { usePRMessagesContext, usePROnClickChoiceContext, usePRPreviewModeContext } from "../../context";
import SimpleMessage from "../SimpleMessage";

const StyleDisabledWrapper = styled("div", StyledHelper.sfw("disabled"))`
  position: relative;
  display: flex;
  align-items: center;

  ${(props) => {
    const opacity = props.disabled ? props.theme.palette.chat.balloon.choiceDisabledOpacity : 1;
    return `
    opacity: ${opacity};
    pointer-events: ${props.disabled ? "none" : "auto"};
    color: ${props.theme.palette.primary.color};
  `;
  }};
`;

const StyledButtonRow = styled(Col, StyledHelper.sfw(["isSelected", "position", "sender"]))`
  .chip-button {
    border-radius: 5px;
    border: ${(props) => props.theme.palette.chat.balloon.choiceBorder};
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: start;
    padding: 4px 10px;
    min-height: 32px;
    line-height: 1;
    ${(props) => {
      const backgroundColorLeft = props.theme.palette.chat.balloon.customer.backgroundColor;
      const backgroundColorRight =
        props.sender === "AGENT"
          ? props.theme.palette.chat.balloon.agent.backgroundColor
          : props.theme.palette.chat.balloon.bot.backgroundColor;

      const color =
        props.position === "right"
          ? props.theme.palette.chat.balloon.customer.color
          : props.sender === "AGENT"
            ? props.theme.palette.chat.balloon.agent.color
            : props.theme.palette.chat.balloon.bot.color;

      let textColor = props.isSelected ? backgroundColorLeft : color;
      const contrastDifference = chroma.contrast(backgroundColorRight, textColor);

      const betterOptimalContrastRatio = 4; // WCAG
      const maximumContrastRatio = 12; // WCAG

      if (contrastDifference < betterOptimalContrastRatio) {
        const diffValue = betterOptimalContrastRatio - contrastDifference;
        let newTextColor = chroma(textColor).brighten(diffValue).hex();
        let newContrastDifference = chroma.contrast(backgroundColorRight, newTextColor);
        if (newContrastDifference < betterOptimalContrastRatio) {
          newTextColor = chroma(textColor).darken(diffValue).hex();
        }
        textColor = newTextColor;
      } else if (contrastDifference > maximumContrastRatio) {
        const diffValue = contrastDifference - maximumContrastRatio;
        textColor = chroma(textColor).darken(diffValue).hex();
      }
      return `       
        background-color: ${backgroundColorRight};
        color: ${textColor};
       ${
         props.isSelected
           ? `
        
        path:first-of-type {
          fill: ${textColor} !important ; 
        }        
        border-color: ${textColor};`
           : ""
       }
      `;
    }}
    &-inline {
      gap: 8px;
    }
  }

  .chat-checkbox {
    width: 18px;
    height: 18px;
    flex: 1 0 18px;
    overflow: visible;
  }
  .svg-primary {
    color: ${(props) => props.selectedColor} !important;
  }
`;

function AutoDisableButton({ messageTime, disabled, style, position, ...props }) {
  const messages = usePRMessagesContext();
  const styles = {};
  let isLastMessage = true;
  if (messages?.[messages?.length - 1]?.messageTime !== messageTime || disabled) {
    isLastMessage = false;
  }
  return (
    <StyleDisabledWrapper disabled={disabled || !isLastMessage}>
      <PRButton {...props} style={{ ...style, ...styles }} />
    </StyleDisabledWrapper>
  );
}

const StyledDiv = styled.div`
  gap: 6px;

  .button-row {
    gap: 4px; // 6px - 2px border
  }
`;

const StyledWebChatCheckboxCheckedIcon = styled(WebChatCheckboxCheckedIcon)``;

function ChoicesMessage({
  historyId,
  isSent,
  format,
  text,
  position,
  choices,
  messageTime,
  messageId,
  likeStatus,
  isLast,
  sender,
  isRag,
  isSuggestion,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const onClickChoice = usePROnClickChoiceContext();
  const hasAnySelected = choices.some((choice) => choice.isSelected);
  const [inlineSelected, setInlineSelected] = useState(null);

  const previewMode = usePRPreviewModeContext();

  return (
    <StyledDiv
      className={classNames("d-flex flex-column ", {
        "align-items-start": position === "left",
        "align-items-end": position === "right",
      })}
      position={position}
      sender={sender}
    >
      {text && (
        <SimpleMessage
          format={format}
          historyId={historyId}
          isLast={isLast}
          isRag={isRag}
          isSent={isSent}
          isSuggestion={isSuggestion}
          likeStatus={likeStatus}
          messageId={messageId}
          messageTime={messageTime}
          position={position}
          sender={sender}
          text={text}
        />
      )}

      <Row
        className={classNames("button-row g-0 d-flex", {
          "flex-row": position === "left",
          "flex-row-reverse": position === "right",
        })}
      >
        {choices.map((choice, i) => {
          const handleClick = (choice) => () => {
            if (previewMode) return;
            setIsClicked(true);
            onClickChoice(choice.value, choice);
            setInlineSelected(choice.value);
          };
          const isSelected = choice.isSelected || inlineSelected === choice.value;
          return (
            <StyledButtonRow key={i} isSelected={isSelected} position={position} sender={sender} xs={"auto"}>
              <AutoDisableButton
                className={classNames("chip-button d-flex align-items-center justify-content-center", {
                  // "button-selected": isSelected,
                })}
                disabled={(!isSelected && !!choice.disabled) || isClicked || hasAnySelected} //|| loading
                messageTime={messageTime}
                onClick={handleClick(choice)}
              >
                <span className="chip-button-inline d-flex align-items-center">
                  {!isSelected && <WebChatCheckboxIcon className="chat-checkbox" />}
                  {isSelected && ( //!loading &&
                    <StyledWebChatCheckboxCheckedIcon
                      className="svg-primary chat-checkbox"
                      position={position}
                      sender={sender}
                      // selectedColor={selectedColorCheckbox}
                    />
                  )}
                  {/* {loading && isSelected && <StyledCircularProgress color="primary" size={18} />} */}

                  <span>{choice.text}</span>
                </span>
              </AutoDisableButton>
            </StyledButtonRow>
          );
        })}
      </Row>
    </StyledDiv>
  );
}

const ChoicesMessageMemoized = memo(ChoicesMessage, (prevProps, nextProps) => {
  return (
    prevProps.text === nextProps.text &&
    prevProps.position === nextProps.position &&
    prevProps.colorPrimary === nextProps.colorPrimary &&
    prevProps.messageId === nextProps.messageId &&
    prevProps.historyId === nextProps.historyId &&
    prevProps.isLast === nextProps.isLast &&
    prevProps.isSent === nextProps.isSent &&
    prevProps.format === nextProps.format &&
    prevProps.isRag === nextProps.isRag &&
    prevProps.messageTime === nextProps.messageTime &&
    prevProps.likeStatus === nextProps.likeStatus &&
    prevProps.sender === nextProps.sender &&
    prevProps.isSuggestion === nextProps.isSuggestion &&
    _.isEqual(prevProps.choices, nextProps.choices)
  );
});

export default ChoicesMessageMemoized;
