import { combineReducers } from "redux";

import channels from "./channels/reducer";
import company from "./company/reducer";
import eCommerce from "./eCommerce/reducer";
import guideDefinition from "./guideDefinition/reducer";
import messageTemplate from "./messageTemplate/reducer";
import permission from "./permission/reducer";
import popupSettings from "./popupSettings/reducer";
import projectSettings from "./projectSettings/reducer";
//combine reducers

const settingsReducer = combineReducers({
  channels,
  company,
  eCommerce,
  guideDefinition,
  messageTemplate,
  permission,
  popupSettings,
  projectSettings,
});

export default settingsReducer;
