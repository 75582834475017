import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { PalPage, PalShadowWrapper, PalTypography } from "@palamar/fe-library";
import { withCardon } from "cardon";
import chroma from "chroma-js";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Md123,
  MdAbc,
  MdAdd,
  MdBookmark,
  MdBookmarkBorder,
  MdCalendarMonth,
  MdCheckCircle,
  MdDescription,
  MdFormatListBulleted,
  MdImage,
  MdKeyboardArrowDown,
  MdLaunch,
  MdMail,
  MdNewspaper,
  MdNoteAdd,
  MdOutlineLocationOn,
  MdOutlineQuestionMark,
  MdRadioButtonChecked,
  MdReviews,
  MdThumbDown,
  MdThumbUp,
  MdVerified,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import styled from "@emotion/styled";
import { Close, Done } from "@mui/icons-material";
import { Box, Chip, Collapse, Grid, Popover } from "@mui/material";

import { ReactComponent as NotificationIcon } from "~assets/images/icons/notification.svg";
import useProjectChange from "~common/hooks/useProjectChange";
import PRButton, { PRButtonGroup } from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDivider from "~components/Generic/PRDivider";
import PRInput, { PRTextAreaFormat } from "~components/Generic/PRInput";
import PRLink from "~components/Generic/PRLink";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import PalTooltip from "~components/mui/PalTooltip";
import {
  apiUrlChatbot,
  chatbotInputRequestType,
  chatbotSessionStatus,
  intentRecordActionType,
  intentRecordActionTypeMap,
  intentRecordTriggerTypeMap,
  tableFilterStorageKey,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import DateHelper from "~helpers/DateHelper";
import RenderHelper from "~helpers/RenderHelper";
import SanitizeHelper from "~helpers/SanitizeHelper";
import Utils from "~helpers/Utils";
import {
  createIntentRecordSamples,
  getIntentRecord,
  updateIntentRecord,
} from "~store/dialogComponents/intentRecords/actions";
import { getIntentList } from "~store/dialogComponents/scenarioManager/actions";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { getLlmSettings } from "~store/socket/livechat/actions";
import { selectLlmSettings } from "~store/socket/livechat/selectors";
import { selectCurrentBot, selectCurrentProject } from "~store/user/selectors";

function ColorizeByPercentage({ value, className, style }) {
  if (value >= 0.85) {
    return (
      <span className={classNames("text-success-600", className)} style={style}>
        {(value * 100).toFixed(1)}%
      </span>
    );
  } else if (value >= 0.65) {
    return (
      <span className={classNames("text-warning-600", className)} style={style}>
        {(value * 100).toFixed(1)}%
      </span>
    );
  } else {
    return (
      <span className={classNames("text-danger", className)} style={style}>
        {(value * 100).toFixed(1)}%
      </span>
    );
  }
}

const StyledRow = styled.tr`
  td {
    ${({ unchecked }) => unchecked && "background-color: #fff2eb !important;"}
  }
`;

function IntentRecordActionModalContent({ get, row }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);

  const [intent, setIntent] = useState(null);
  const [addAs, setAddAs] = useState(row?.intent ? "existing" : "another");

  const [textData, setTextData] = useState(row.trigger_user_message?.text || "");
  const addAsOptions = useMemo(() => {
    return [
      {
        value: "existing",
        label: t("component.intentRecordActionModalContent.addAsExistingIntent"),
        disabled: !row?.intent,
      },
      {
        value: "another",
        label: t("component.intentRecordActionModalContent.addAsAnotherIntent"),
      },
      {
        value: "another_prediction",
        label: t("component.intentRecordActionModalContent.addAsIntentPrediction"),
      },
      {
        value: "new",
        label: t("component.intentRecordActionModalContent.addAsNewIntent"),
      },
    ].filter(Boolean);
  }, [row?.intent, t]);

  useEffect(() => {
    if (addAs === "another" || addAs === "another_prediction") {
      setIntent(null);
    }
  }, [addAs]);

  const handleSubmit = async () => {
    if (addAs === "existing") {
      await dispatch(
        updateIntentRecord(currentProject.id, {
          id: row.id,
          action_type: intentRecordActionType.ADD_SAMPLE_QUESTION,
          intent: row.intent,
        })
      );

      await dispatch(
        createIntentRecordSamples(currentProject.id, currentBot.id, row.intent, {
          intent_text: textData,
          intent: row.intent,
        })
      );
      AlertHelper.showSuccess(t("component.intentRecordActionModalContent.adAsExistingSuccessAlert"));
    } else if (addAs === "another") {
      await dispatch(
        updateIntentRecord(currentProject.id, {
          id: row.id,
          action_type: intentRecordActionType.CHANGE_INTENT,
          intent: intent.id,
        })
      );

      await dispatch(
        createIntentRecordSamples(currentProject.id, currentBot.id, intent.id, {
          intent_text: textData,
          intent: intent.id,
        })
      );
      AlertHelper.showSuccess(t("component.intentRecordActionModalContent.adAsAnotherSuccessAlert"));
    } else if (addAs === "another_prediction") {
      await dispatch(
        updateIntentRecord(currentProject.id, {
          id: row.id,
          action_type: intentRecordActionType.CHANGE_INTENT,
          intent: intent.id,
        })
      );

      await dispatch(
        createIntentRecordSamples(currentProject.id, currentBot.id, intent.id, {
          intent_text: textData,
          intent: intent.id,
        })
      );
      AlertHelper.showSuccess(t("component.intentRecordActionModalContent.adAsAnotherSuccessAlert"));
    } else if (addAs === "new") {
      await dispatch(
        updateIntentRecord(currentProject.id, {
          id: row.id,
          action_type: intentRecordActionType.CREATE_INTENT,
        })
      );
    }

    get(true)();
  };

  const handleSearch = async (searchText, callback, signal, valueProp) => {
    const response = await dispatch(
      getIntentList(currentProject.id, currentBot.id, {
        params: {
          text__icontains: searchText,
          limit: 20,
          use_in_prediction: true,
        },
        signal,
      })
    );
    const responseList = response?.results || [];

    // if (!responseList.find((item) => item.id === row?.intent)) {
    //   const intent = await dispatch(getIntent(currentProject.id, currentBot.id, row.intent));
    //   setIntent(intent);
    //   responseList.push(intent);
    // }

    return responseList;
  };
  const handlePredictionSearch = async (searchText, callback, signal, valueProp) => {
    const response = await dispatch(
      getIntentList(currentProject.id, currentBot.id, {
        params: {
          text__icontains: searchText,
          limit: 20,
          use_in_prediction: false,
        },
        signal,
      })
    );
    const responseList = response?.results || [];

    // if (!responseList.find((item) => item.id === row?.intent)) {
    //   const intent = await dispatch(getIntent(currentProject.id, currentBot.id, row.intent));
    //   setIntent(intent);
    //   responseList.push(intent);
    // }

    return responseList;
  };

  const handleTextChange = (e) => {
    setTextData(e.target.value);
  };
  const activeIntentId = addAs === "existing" ? row.intent : intent?.id;
  const activeIntentText =
    addAs === "existing" ? row.intent_display_text || row.intent_text : intent?.display_text || intent?.text;
  return (
    <PRModal
      // onClick={handleSubmit}
      size="lg"
      submitText={""}
      title={t("component.intentRecordActionModalContent.title")}
      onClose={get(false)}
    >
      <Row className="gx-2 gy-3">
        <Col className="fs-4" xs="12">
          {t("component.intentRecordActionModalContent.description")}
          <PRDivider color={"secondary"} />
        </Col>
        <Col xs="12">
          <div>
            <PRButtonGroup options={addAsOptions} value={addAs} onChange={setAddAs} />
          </div>
        </Col>
        {(addAs === "existing" || addAs === "another" || addAs === "another_prediction") && (
          <>
            <Col xs="12">
              <Label>
                {t("common.intent")}:{" "}
                {!!activeIntentId && (
                  <PRLink newTab to={`/chatbot/intent/form/${activeIntentId}`}>
                    {activeIntentText}
                  </PRLink>
                )}
              </Label>
            </Col>
          </>
        )}
        {(addAs === "another" || addAs === "another_prediction") && (
          <Col xs="12">
            <Label>{t("common.targetIntent")}: </Label>
            <PRSelect
              lazy
              menuPortal
              className="w-100"
              id="intent"
              isClearable={false}
              labelSelector="text"
              loadOptions={handleSearch}
              name="intent"
              placeholder={t("common.searchIntentPlaceholder")}
              value={intent}
              valueSelector="id"
              onChange={setIntent}
            />
          </Col>
        )}{" "}
        {!!activeIntentId && (addAs === "existing" || addAs === "another") && (
          <>
            <Label size="md">{t("component.intentRecordActionModalContent.currentIntentSamples")}:</Label>
            <PRTable
              inline
              columns={[
                {
                  label: t("common.sample"),
                  key: "intent_text",
                },
              ]}
              url={`${apiUrlChatbot.getIntentRecordSampleIntent.format(
                currentProject.id,
                currentBot.id,
                activeIntentId
              )}`}
            />
          </>
        )}
        <Col xs="12">
          <Label>{t("component.intentRecordActionModalContent.newIntentSample")}:</Label>
          <PRInput value={textData} onChange={handleTextChange}></PRInput>
        </Col>
        <Col xs="12">
          <PRButton
            icon={MdAdd}
            linkProps={{
              newTab: true,
            }}
            onClick={handleSubmit}
            {...(addAs === "new"
              ? {
                  link: `/chatbot/intent/form/?question=${textData}`,
                  disabled: !textData,
                }
              : addAs === "existing"
                ? {
                    disabled: !textData || !activeIntentId,
                  }
                : {
                    // link: `/chatbot/intent/form/${activeIntentId}/?sample=${textData}`,
                    disabled: !textData || !activeIntentId,
                  })}
          >
            {addAs === "new"
              ? t("component.intentRecordActionModalContent.addAsNewIntent")
              : addAs === "existing"
                ? t("component.intentRecordActionModalContent.addAsExistingIntent")
                : t("component.intentRecordActionModalContent.addAsAnotherIntent")}
          </PRButton>
        </Col>
      </Row>
    </PRModal>
  );
}
const IntentRecordActionModal = withCardon(IntentRecordActionModalContent, { destroyOnHide: true });

const StyledPage = styled(PalPage)`
  & > .MuiBox-root {
    background-color: ${({ theme, order }) =>
      order
        ? chroma(theme.palette.grey[100])
            .darken(order * 0.1)
            .hex()
        : "white"};
    /* border-right: 1px solid #f4f4f4;
    border-left: 1px solid #f4f4f4; */
    border-radius: 0;
    padding-right: 0;
  }
  background-color: white;
`;

const StyledTable = styled(PRTable)`
  .pr-table & > .table-wrapper > table > thead {
    th {
      background-color: ${({ theme, order }) =>
        chroma(theme.palette.grey[100])
          .darken(order * 0.1)
          .hex()};
    }

    th:first-child {
      border-top-left-radius: 0;
    }
    th:last-child {
      border-top-right-radius: 0;
    }
  }
  .pr-table & > .table-wrapper > table > tbody > tr:nth-child(odd) {
    td {
      background-color: ${({ theme, order }) =>
        chroma(theme.palette.grey[100])
          .darken((order + 1) * 0.1)
          .hex()};
    }
  }
  .pr-table & > .table-wrapper > table > tbody > tr:nth-child(even) {
    td {
      background-color: ${({ theme, order }) =>
        chroma(theme.palette.grey[100])
          .darken((order + 1) * 0.1)
          .hex()};
    }
  }
`;

const ReviewInfoModal = withCardon(
  function ReviewInfoModalContent({ botReview, agentReview, get }) {
    const { t } = useTranslation();

    return (
      <PRModal title="Review Info" onClose={get(false)}>
        <Grid container spacing={4}>
          {botReview && (
            <Grid item xs={12}>
              <PalTypography variant="h6">{t("dashboard.intentRecordList.botReview")}</PalTypography>
              <PalTypography>{botReview}</PalTypography>
            </Grid>
          )}
          {agentReview && (
            <Grid item xs={12}>
              <PalTypography variant="h6">{t("dashboard.intentRecordList.agentReview")}</PalTypography>
              <PalTypography>{agentReview}</PalTypography>
            </Grid>
          )}
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const chatbotActiveSessionStatus = [
  chatbotSessionStatus.NEW,
  chatbotSessionStatus.BOT_WAIT,
  chatbotSessionStatus.BOT_CHAT,
  chatbotSessionStatus.AGENT_WAIT,
  chatbotSessionStatus.BOT_CHAT_AGENT_WAIT,
  chatbotSessionStatus.BOT_REPLY,
  chatbotSessionStatus.AGENT_STARTED,
  chatbotSessionStatus.AGENT_CHAT,
  chatbotSessionStatus.FEEDBACK,
  chatbotSessionStatus.BOT_JOINED,
  chatbotSessionStatus.TICKET,
  chatbotSessionStatus.ESW,
];

const CollapseOpenCloseRotatorWrapper = styled.div`
  transform: rotate(${({ open }) => (open ? 0 : -90)}deg);
  transition: transform 0.3s;
`;
const ClusterRowRenderer = ({ row, index, children }) => {
  const dispatch = useDispatch();

  const tableRef = useRef(null);

  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const popupSettings = useSelector(selectPopupSettingsState);

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const handleClick = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  useProjectChange(() => {
    tableRef.current.resetPagination();
  }, []);

  const clonedChildren = useMemo(
    () =>
      React.isValidElement(children)
        ? React.cloneElement(children, {
            onClick: handleClick,
            children: React.Children.map(children.props.children, (child, idx) => {
              if (idx === 1 && React.isValidElement(child)) {
                return (
                  <td>
                    <CollapseOpenCloseRotatorWrapper open={open}>
                      <MdKeyboardArrowDown />
                    </CollapseOpenCloseRotatorWrapper>
                  </td>
                );
              }
              return child;
            }),
          })
        : children,
    [children, open]
  );

  const isLLM = useMemo(() => {
    return currentBot?.prediction_type === "EMBEDDING_WITH_RAG";
  }, [currentBot?.prediction_type]);

  const columns = useMemo(() => {
    return [
      // {
      //   label: "Bot",
      //   key: "bot_id",
      //   render: (row) => {
      //     const bot = bots.find((bot) => bot.id === row.bot_id);
      //     return bot?.name;
      //   },
      // },
      {
        label: t("dashboard.intentRecordList.userMessage"),
        key: "trigger_user_message",
        style: {
          maxWidth: "200px",
          minWidth: "200px",
        },
        render: (row) => {
          if (!row.trigger_user_message) return;
          let displayText;
          let DisplayIcon;

          const msg = row.trigger_user_message?.message_data;
          const msgType = msg.type?.toLowerCase();
          // console.log("msg: ", msg);
          if (msg) {
            const text = msg.unformattedText || msg.text;
            if (msgType === "text") {
              displayText = text;
              if (msg.format === PRTextAreaFormat.markdown) {
                displayText = RenderHelper.renderMd(text, {
                  renderAsPlain: true,
                  disableUtmLinks: true,
                });
                displayText = SanitizeHelper.plainText(displayText || "");
              } else if (msg.format === PRTextAreaFormat.html) {
                const sanitizedPlainText = SanitizeHelper.plainText(text || "");
                displayText = sanitizedPlainText;
                const lines = displayText.split(/\r?\n/);
                displayText = lines?.[0] || ""; // first line of html
              }
              if (msg.isChoiceText) {
                DisplayIcon = MdRadioButtonChecked;
              }
            } else if (msgType === "choice") {
              displayText = text;
              DisplayIcon = MdOutlineQuestionMark;
            } else if (msgType === "file") {
              displayText = msg.fileName;
              DisplayIcon = MdDescription;
            } else if (msgType === "bot_file") {
              displayText = t("dashboard.intentRecordList.botFile");
              DisplayIcon = MdNoteAdd;
            } else if (msgType === "image") {
              displayText = msg.fileName || t("common.image");
              DisplayIcon = MdImage;
            } else if (msgType === "location") {
              displayText =
                [msg.locationInfo?.name, msg.locationInfo?.address].filter(Boolean).join(" - ") || t("common.location");
              DisplayIcon = MdOutlineLocationOn;
            } else if (msgType === "inputRequest") {
              if (msg.inputFormat.type === chatbotInputRequestType.form) {
                displayText = t("dashboard.intentRecordList.formRequest");
                DisplayIcon = MdNewspaper;
              } else if (
                [chatbotInputRequestType.custom, chatbotInputRequestType.select].includes(msg.inputFormat.type)
              ) {
                displayText = t("dashboard.intentRecordList.choiceRequest");
                DisplayIcon = MdCheckCircle;
              } else if (
                [chatbotInputRequestType.date, chatbotInputRequestType.date_range].includes(msg.inputFormat.type)
              ) {
                displayText = t("dashboard.intentRecordList.dateRequest");
                DisplayIcon = MdCalendarMonth;
              } else if (msg.inputFormat.type === chatbotInputRequestType.file) {
                displayText = t("dashboard.intentRecordList.fileRequest");
                DisplayIcon = MdDescription;
              } else if (
                [chatbotInputRequestType.only_numbers, chatbotInputRequestType.range].includes(msg.inputFormat.type)
              ) {
                displayText = t("dashboard.intentRecordList.numberRequest");
                DisplayIcon = Md123;
              } else {
                displayText = t("dashboard.intentRecordList.textInputRequest");
                DisplayIcon = MdAbc;
              }
            } else if (msgType === "notification") {
              displayText = text;
              DisplayIcon = NotificationIcon;
            }
          }

          const isReactComponent = typeof displayText === "object" && displayText !== null && displayText.$$typeof;
          if (typeof displayText === "string" || typeof displayText === "function" || isReactComponent) {
            return (
              <PRLink
                newTab
                to={`/contactcenter/history/${row.session}/${
                  row?.trigger_user_message?.history_id
                    ? Utils.qs({ messageId: row?.trigger_user_message?.history_id, p: 1 })
                    : ``
                }`}
              >
                <Grid container alignItems={"end"} display={"flex"} flexWrap={"nowrap"} gap={"3px"}>
                  {DisplayIcon && (
                    <Grid item zeroMinWidth display={"flex"} sx={{ svg: { width: "auto", height: 15 } }} xs="auto">
                      <DisplayIcon />
                    </Grid>
                  )}
                  <Grid item>
                    <>{typeof displayText === "function" ? displayText() : displayText}</>
                  </Grid>
                </Grid>
              </PRLink>
            );
          }
        },
      },
      {
        noWrap: true,
        label: t("common.type"),
        key: "trigger_type",
        render: (row) => {
          return intentRecordTriggerTypeMap[row.trigger_type] || row.trigger_type;
        },
      },
      {
        // noWrap: true,
        label: t("dashboard.intentRecordList.generatedMessage"),
        key: "generated_message",
        style: {
          maxWidth: "350px",
        },
        render: (row) => {
          const generatedMessage = row?.generated_message;
          if (generatedMessage?.type === "TEXT") {
            if (generatedMessage.format === "html") {
              let sanitizedHtml = SanitizeHelper.html(generatedMessage.text);
              return (
                <PalShadowWrapper
                  style={{
                    maxHeight: 200,
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                </PalShadowWrapper>
              );
            } else if (generatedMessage.format === "markdown") {
              const htmlContent = RenderHelper.renderMd(generatedMessage.text || "", {
                renderAsPlain: true,
                disableUtmLinks: true,
              });
              let sanitizedHtml = SanitizeHelper.html(htmlContent);
              return (
                <PalShadowWrapper
                  style={{
                    maxHeight: 200,
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                </PalShadowWrapper>
              );
            } else {
              return generatedMessage.text;
            }
          }
          return generatedMessage?.type || "";
        },
      },
      {
        label: t("dashboard.intentRecordList.aiAnalysis2"), //; t("dashboard.intentRecordList.aiAnalysis"),
        key: "is_succesful",
        render: (row) => {
          return (
            <Box
              sx={{
                svg: {
                  fontSize: "1.25rem",
                  fill: row.is_succesful ? (theme) => theme.palette.success.main : (theme) => theme.palette.error.main,
                },
              }}
            >
              {row.is_succesful ? <Done /> : <Close />}
            </Box>
          );
        },
      },
      !isLLM && {
        label: t("common.choices"),
        key: "options",
        render: (row) => {
          return (
            <Grid container spacing={1}>
              {row.options?.map((option, index) => {
                const text = option.intent_text;

                const truncateLength = 20;
                const isTruncated = text?.length > truncateLength;
                const displayText = isTruncated ? text?.substring(0, truncateLength) + "..." : text;

                return (
                  <Grid key={index} item xs={12}>
                    <PRTooltip title={option.value}>
                      <Chip
                        color="secondary"
                        label={
                          <>
                            {isTruncated ? <PalTooltip title={text}>{displayText}</PalTooltip> : displayText}
                            (
                            <ColorizeByPercentage
                              style={{
                                filter:
                                  option.intent_text === row.trigger_user_message?.text ? "brightness(1.42)" : "none",
                              }}
                              value={option.confidence}
                            />
                            )
                          </>
                        }
                        size="small"
                        variant={option.intent_text === row.trigger_user_message?.text ? "filled" : "outlined"}
                      />
                    </PRTooltip>
                  </Grid>
                );
              })}
            </Grid>
          );
        },
      },
      {
        noWrap: true,
        label: t("dashboard.intentRecordList.intentName"),
        key: "intent_display_text",
        render: (row) => {
          const text = row.intent_display_text || row.intent_text;

          const truncateLength = 50;

          const isTruncated = text?.length > truncateLength;
          const displayText = isTruncated ? text?.substring(0, truncateLength) + "..." : text;

          return (
            <PRLink newTab to={`/chatbot/intent/form/${row.intent}`}>
              {isTruncated ? <PalTooltip title={text}>{displayText}</PalTooltip> : displayText}
            </PRLink>
          );
        },
      },
      // {
      //   style: {
      //     whiteSpace: "nowrap",
      //   },
      //   label: "Intent Tag",
      //   key: "intent_tag_key",
      //   render: (row) => (
      //     <PRButton className="p-0" color="link" link={`/chatbot/intent-tags/form/${row.intent_tag}/`}>
      //       {row.intent_tag_key}
      //     </PRButton>
      //   ),
      // },
      ...(!isLLM
        ? [
            {
              label: t("dashboard.intentRecordList.confidence"),
              key: "confidence",
              render: (row) => {
                if (!row.confidence) return "";
                return <ColorizeByPercentage className="fw-medium" value={row.confidence} />;
              },
            },
          ]
        : []),
      // popupSettings.show_thumps_up && {
      //   label: "Like Status",
      //   key: "like_status",
      //   render: (row) => {
      //     if (row.like_status) {
      //       return <span className="text-success-600">Yes</span>;
      //     }
      //     return "";
      //   },
      // },
      // popupSettings.show_thumps_up && {
      //   label: "Dislike Status",
      //   key: "dislike_status",
      //   render: (row) => {
      //     if (row.dislike_status) {
      //       return <span className="text-danger">Yes</span>;
      //     }
      //     return "";
      //   },
      // },
      {
        noWrap: true,
        key: "created",
        label: t("dashboard.intentRecordList.created"),
        render: (item) => (
          <div>
            <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
            </PRTooltip>
          </div>
        ),
      },
      // {
      //   noWrap: true,
      //   label: "Reviewed By",
      //   key: "reviewed_by_name",
      //   render: (row) => {
      //     return row.reviewed_by_name || "-";
      //   },
      // },
      // {
      //   noWrap: true,
      //   key: "reviewed_date",
      //   label: "Reviewed Date",
      //   render: (item) => {
      //     if (!item.reviewed_date) return "-";
      //     return (
      //       <div>
      //         <PRTooltip title={DateHelper.getDateTimeLocal(item.reviewed_date).format("LLT")}>
      //           <span>{DateHelper.getDateTimeLocal(item.reviewed_date).fromNow()}</span>
      //         </PRTooltip>
      //       </div>
      //     );
      //   },
      // },
      // {
      //   noWrap: true,
      //   label: "Reviewed Status",
      //   key: "action_type",
      //   render: (row) => {
      //     return (
      //       <Box component="span" fontWeight={row.action_type === intentRecordActionType.NONE ? "300" : "700"}>
      //         {intentRecordActionTypeMap[row.action_type] || row.action_type}
      //       </Box>
      //     );
      //   },
      // },
      // {
      //   noWrap: true,
      //   label: "Rev. Info",
      //   key: "action_type",
      //   render: (row) => {
      //     return (
      //       <Box component="span" fontWeight={row.action_type === intentRecordActionType.NONE ? "300" : "700"}>
      //         <PalIconButton size="small">
      //           <CheckCircleOutline
      //             color={
      //               row.action_type === intentRecordActionType.NONE
      //                 ? "secondary"
      //                 : row.action_type === intentRecordActionType.IGNORE
      //                 ? "error"
      //                 : row.action_type === intentRecordActionType.CHANGE_INTENT
      //                 ? "warning"
      //                 : row.action_type === intentRecordActionType.CREATE_INTENT
      //                 ? "success"
      //                 : row.action_type === intentRecordActionType.ADD_SAMPLE_QUESTION
      //                 ? "primary"
      //                 : "secondary"
      //             }
      //           />
      //         </PalIconButton>
      //       </Box>
      //     );
      //   },
      // },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          const handleClickLiked = (id) => async (e) => {
            const record = await dispatch(
              updateIntentRecord(currentProject.id, {
                id,
                action_type: intentRecordActionType.IGNORE,
              })
            );

            // const record = await dispatch(getIntentRecord(currentProject.id, id));
            tableRef.current?.setVisibleRows(
              tableRef.current?.getVisibleRows().map((data) => (data.id === id ? record : data))
            );
          };

          const handleClickDisliked = (row) => async (e) => {
            const result = await IntentRecordActionModal.show({ row });
            if (result) {
              const record = await dispatch(getIntentRecord(currentProject.id, row.id));
              tableRef.current?.setVisibleRows(
                tableRef.current?.getVisibleRows().map((data) => (data.id === row.id ? record : data))
              );
            }
          };
          const handleClickFactList = (row) => async () => {
            await FactListModal.show({ list: row.fact_list });

            // setFactList(row.fact_list);
          };

          const handleClickShowTypeDetails = (row) => async () => {
            ReviewStatusModal.show({ row });
          };

          const handleClickAddRemoveBookmark = (row) => async () => {
            await dispatch(updateIntentRecord(currentProject.id, { id: row.id, is_flagged: !row.is_flagged }));
            const record = await dispatch(getIntentRecord(currentProject.id, row.id));
            tableRef.current?.setVisibleRows(
              tableRef.current?.getVisibleRows().map((data) => (data.id === row.id ? record : data))
            );
          };

          return (
            <div className="d-flex justify-content-center">
              <PRButton
                outline
                icon={MdLaunch}
                link={`/contactcenter/history/${row.session}/`}
                linkProps={{ newTab: true }}
                size="sm"
                tooltipText={t("common.openInNewTab")}
              />
              <PRButton
                className="ms-1"
                color={row.is_flagged ? "purple" : "secondary"}
                icon={row.is_flagged ? MdBookmark : MdBookmarkBorder}
                outline={!row.is_flagged}
                size="sm"
                tooltipText={
                  row.is_flagged
                    ? t("dashboard.intentRecordList.removeBookmark")
                    : t("dashboard.intentRecordList.addBookmark")
                }
                onClick={handleClickAddRemoveBookmark(row)}
              />
              <PRButton
                outline
                className="ms-1"
                color={
                  row.action_type === intentRecordActionType.NONE
                    ? "secondary"
                    : row.action_type === intentRecordActionType.IGNORE
                      ? "danger"
                      : row.action_type === intentRecordActionType.CHANGE_INTENT
                        ? "warning"
                        : row.action_type === intentRecordActionType.CREATE_INTENT
                          ? "success"
                          : row.action_type === intentRecordActionType.ADD_SAMPLE_QUESTION
                            ? "primary"
                            : "secondary"
                }
                icon={MdVerified}
                // icon={MdCheckCircleOutline}
                // link={`/chatbot/intent/form/${row.id}`}
                size="sm"
                tooltipText={
                  row.action_type === intentRecordActionType.NONE
                    ? t("dashboard.intentRecordList.noneTooltip")
                    : row.action_type === intentRecordActionType.IGNORE
                      ? t("dashboard.intentRecordList.ignoreTooltip")
                      : row.action_type === intentRecordActionType.CHANGE_INTENT
                        ? t("dashboard.intentRecordList.changeIntentTooltip")
                        : row.action_type === intentRecordActionType.CREATE_INTENT
                          ? t("dashboard.intentRecordList.createIntentTooltip")
                          : row.action_type === intentRecordActionType.ADD_SAMPLE_QUESTION
                            ? t("dashboard.intentRecordList.addSampleQuestionTooltip")
                            : t("dashboard.intentRecordList.unknownTooltip")
                }
                onClick={handleClickShowTypeDetails(row)}
              />
              {isLLM && (
                <PRButton
                  outline
                  className="ms-1"
                  color={row.fact_list?.length ? "primary" : "secondary"}
                  tooltipText={t("dashboard.intentRecordList.factList")}
                  onClick={handleClickFactList(row)}
                  icon={MdFormatListBulleted}
                  // link={`/chatbot/intent/form/${row.id}`}
                  size="sm"
                />
              )}
              <PRButton
                outline
                className="ms-1"
                color="primary"
                tooltipText={t("dashboard.intentRecordList.likeButtonTooltip")}
                onClick={handleClickLiked(row.id)}
                icon={MdThumbUp}
                // link={`/chatbot/intent/form/${row.id}`}
                size="sm"
              />
              <PRButton
                outline
                className="ms-1"
                color="danger"
                icon={MdThumbDown}
                size="sm"
                tooltipText={t("dashboard.intentRecordList.dislikeButtonTooltip")}
                onClick={handleClickDisliked(row)}
              />
            </div>
          );
        },
      },
    ].filter(Boolean);
  }, [currentProject.id, dispatch, isLLM, t]);

  const propsOfClonedItem = Object.fromEntries(Object.entries(clonedChildren.props));
  return (
    <>
      <Box
        {...propsOfClonedItem}
        component="tr"
        sx={{
          td: {
            backgroundColor: chatbotActiveSessionStatus.includes(row.session_status) ? "#ffeee5 !important" : "inherit",
          },
        }}
      />
      <tr>
        {/* {allChildChildren} */}
        <td colSpan={100} style={{ padding: 0 }}>
          <Collapse in={open}>
            <StyledPage order={1} title={row.name}>
              <Grid container spacing={2}>
                <Grid item sx={(theme) => ({ marginTop: theme.spacing(-1) })} xs={12}>
                  <PalTypography variant="p">{row.summary}</PalTypography>
                </Grid>
                <Grid item xs={12}>
                  <StyledTable
                    ref={tableRef}
                    noCount
                    noPagination
                    columns={columns}
                    data={row.intent_records || []}
                    order={1}
                    // rowRenderer={SubClusterRowRenderer}
                  />
                </Grid>
              </Grid>
            </StyledPage>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

const TicketPopoverButton = ({ tickets = [] }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    //stop event propagation
    event.stopPropagation();
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "ticket-popover" : undefined;

  return (
    <div>
      <PRButton
        outline
        color={tickets.length ? "primary" : "secondary"}
        disabled={!tickets.length}
        icon={MdMail}
        size="sm"
        tooltipText={t("dashboard.intentRecordList.tickets")}
        onClick={handleClick}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        id={id}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        <div style={{ padding: "10px", maxWidth: "300px", maxHeight: "400px", overflowY: "auto" }}>
          <Box>
            {t("dashboard.intentRecordList.tickets")} ({tickets.length})
          </Box>
          {tickets.map((ticket) => {
            const textMax100 = ticket.text.length > 100 ? ticket.text.substring(0, 100) + "..." : ticket.text;
            return (
              <div key={ticket.id} style={{ marginBottom: "5px" }}>
                <PRLink newTab to={`/helpdesk/ticket/form/${ticket.id}/`}>
                  {textMax100}
                </PRLink>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default function IntentRecordList() {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const llmSettings = useSelector(selectLlmSettings);
  const popupSettings = useSelector(selectPopupSettingsState);

  useProjectChange(() => {
    tableRef.current.resetPagination();
  }, []);

  useEffect(() => {
    dispatch(getLlmSettings(currentProject.id));
    // dispatch(getProjectSettings(currentProject.id));
  }, [dispatch, currentProject?.id]);

  const isLLM = useMemo(() => {
    return currentBot?.prediction_type === "EMBEDDING_WITH_RAG";
  }, [currentBot?.prediction_type]);

  const columns = useMemo(() => {
    return [
      {
        render: () => <span />,
        style: {
          width: "10px",
        },
      },
      {
        key: "main_topic",
        label: t("dashboard.intentRecordList.mainTopic"),
      },
      isLLM &&
        llmSettings?.genai_analysis_active && {
          label: t("dashboard.intentRecordList.aiAnalysis"),
          key: "ai_analysis",
        },
      {
        key: "session_type",
        label: t("dashboard.intentRecordList.sessionType"),
      },
      {
        key: "platform",
        label: t("dashboard.intentRecordList.platform"),
      },
      {
        key: "created",
        label: t("dashboard.intentRecordList.created"),
        render: (item) => (
          <div>
            <PRTooltip title={DateHelper.getDateTimeLocal(item.created).format("LLT")}>
              <span>{DateHelper.getDateTimeLocal(item.created).fromNow()}</span>
            </PRTooltip>
          </div>
        ),
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        fixed: "right",
        render: (row) => {
          const handleClickShowReviewModal = (row) => async (e) => {
            ReviewInfoModal.show({ botReview: row.bot_review, agentReview: row.agent_review });
            e.stopPropagation();
          };

          return (
            <div className="d-flex justify-content-center">
              <TicketPopoverButton tickets={row.tickets} />
              <PRButton
                className="ms-1"
                color={row.bot_review || row.agent_review ? "primary" : "secondary"}
                disabled={!row.bot_review && !row.agent_review}
                icon={MdReviews}
                outline={!row.is_flagged}
                size="sm"
                tooltipText={t("dashboard.intentRecordList.reviewInfo")}
                onClick={handleClickShowReviewModal(row)}
              />
            </div>
          );
        },
      },
    ].filter(Boolean);
  }, [isLLM, t, llmSettings?.genai_analysis_active]);

  const filters = useMemo(() => {
    return [
      {
        key: "bot",
        // options: currentProject.chatbot_list.map((bot) => ({
        //   label: bot.name,
        //   value: bot.id,
        // })),
        visible: false,
      },
      // {
      //   type: "number",
      //   key: "confidence",
      //   lookup_keys: ["lte", "gte"],
      //   default_lookup_key: "exact",
      //   multiple: false,
      //   min: 0,
      //   max: 100,
      //   multiplier: 1 / 100,
      // },
      {
        key: "trigger_type",
        labelRenderer: (data) => {
          return intentRecordTriggerTypeMap[data?.label] || data?.label;
        },
      },
    ];
  }, []);

  // const rowRenderer = ({ row, index, children }) => {
  //   const allChildChildren = children.props.children;
  //   const isReviewed = row.action_type !== intentRecordActionType.NONE;

  //   return <StyledRow unchecked={!isReviewed}> {allChildChildren}</StyledRow>;
  // };
  const parentName = [
    {
      label: t("layout.sidebar.intentRecords"),
    },
  ];
  return (
    <PRContainer
      description={t("dashboard.intentRecordList.description")}
      name={t("common.chatbot")}
      parentName={parentName}
    >
      <PRTable
        ref={tableRef}
        columns={columns}
        filters={filters}
        order={0}
        rowRenderer={ClusterRowRenderer}
        storageKey={tableFilterStorageKey.intentRecordList}
        url={`${apiUrlChatbot.getIntentRecordsV2.format(currentProject.id)}?bot=${currentBot.id}`}
      />
    </PRContainer>
  );
}

const FactListModal = withCardon(
  ({ get, list }) => {
    const { t } = useTranslation();
    const handleClose = () => {
      get(false)();
    };
    return (
      <PRModal size="xl" submitText={""} title={t("dashboard.intentRecordList.factList")} onClose={handleClose}>
        <PRTable
          columns={[
            {
              label: t("dashboard.intentRecordList.factId"),
              key: "fact_id",
            },
            {
              label: t("dashboard.intentRecordList.intentId"),
              key: "intent_id",
              render: (row) => {
                return (
                  <PRLink newTab to={`/chatbot/intent/form/${row.intent_id}`}>
                    {row.intent_id}
                  </PRLink>
                );
              },
            },
            {
              label: t("dashboard.intentRecordList.intentDisplayText"),
              key: "intent_display_text",
            },
            {
              label: t("dashboard.intentRecordList.intentText"),
              key: "intent_text",
            },
            {
              label: t("dashboard.intentRecordList.factText"),
              key: "fact_text",
              render: (row) => {
                const text = row.fact_text;

                const truncateLength = 50;

                const isTruncated = text?.length > truncateLength;
                const displayText = isTruncated ? text?.substring(0, truncateLength) + "..." : text;

                return isTruncated ? <PalTooltip title={text}>{displayText}</PalTooltip> : displayText;
              },
            },
          ]}
          data={list}
        />
      </PRModal>
    );
  },
  { destroyOnHide: true }
);

const ReviewStatusModal = withCardon(
  function ReviewStatusModalContent({ get, row }) {
    const { t } = useTranslation();
    const handleClose = () => {
      get(false)();
    };
    return (
      <PRModal size="lg" submitText={""} title={t("dashboard.intentRecordList.reviewStatus")} onClose={handleClose}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <PalTypography>{t("common.actionType")}:</PalTypography>
          </Grid>
          <Grid item xs={8}>
            <PalTypography fontWeight="bold">
              {intentRecordActionTypeMap?.[row.action_type] || row.action_type}
            </PalTypography>
          </Grid>
          <Grid item xs={4}>
            <PalTypography>{t("dashboard.intentRecordList.reviewedBy")}:</PalTypography>
          </Grid>
          <Grid item xs={8}>
            <PalTypography fontWeight="bold">{row.reviewed_by_name || "-"}</PalTypography>
          </Grid>

          <Grid item xs={4}>
            <PalTypography>{t("dashboard.intentRecordList.reviewedDate")}:</PalTypography>
          </Grid>

          <Grid item xs={8}>
            <PalTypography fontWeight="bold">
              {row?.reviewed_date ? DateHelper.getDateTimeLocal(row?.reviewed_date).format("LLT") : "-"}
            </PalTypography>
          </Grid>

          <Grid item xs={4}>
            <PalTypography>{t("dashboard.intentRecordList.reviewedStatus")}:</PalTypography>
          </Grid>
          <Grid item xs={8}>
            <PalTypography fontWeight="bold">
              {intentRecordActionTypeMap?.[row.action_type] || row.action_type}
            </PalTypography>
          </Grid>
        </Grid>
      </PRModal>
    );
  },
  { destroyOnHide: true }
);
