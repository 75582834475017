import { apiUrlOnboarding } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setSourceWebPage = (payload) => ({
  type: at.SET_SOURCE_WEB_PAGE,
  payload,
});

export const getSourceWebPage = (projectId, id) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.getWebpageDetail.format(projectId, id), {
    // onSuccess: setSourceWebPage,
    // loading: true,
    // successMsg: true,
  });
};

export const createOrUpdateSourceWebPage =
  (projectId, { id, url, content }) =>
  async (_dispatch, _getState) => {
    const requestUrl = id
      ? apiUrlOnboarding.getWebpageDetail.format(projectId, id)
      : apiUrlOnboarding.getWebpage.format(projectId);
    return Network.request(requestUrl, {
      method: id ? "PUT" : "POST",
      data: { id, url, content },
      loading: true,
      successMsg: true,
    });
  };

export const deleteSourceWebPage = (projectId, id) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.getWebpageDetail.format(projectId, id), {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const getSourceFile = (projectId, id) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.getFileDetail.format(projectId, id), {
    // loading: true,
    // successMsg: true,
  });
};

export const createOrUpdateSourceFile =
  (projectId, { id, file, ...rest }) =>
  async (_dispatch, _getState) => {
    const formData = new FormData();
    if (file) {
      formData.append("media_file", file);
    }
    const requestUrl = id
      ? apiUrlOnboarding.getFileDetail.format(projectId, id)
      : apiUrlOnboarding.getFile.format(projectId);

    if (id) {
      delete rest.media_file;
    }
    return Network.request(requestUrl, {
      method: id ? "PUT" : "POST",
      data: id ? { id, ...rest } : formData,
      loading: true,
      successMsg: true,
    });
  };

export const deleteSourceFile = (projectId, id) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.getFileDetail.format(projectId, id), {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const getRagState = (projectId, botId) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.getRagIntentConfig.format(projectId, botId), {
    loading: true,
    successMsg: false,
  });
};

export const updateRagState =
  (projectId, botId, payload, options = {}) =>
  async (_dispatch, _getState) => {
    return Network.request(apiUrlOnboarding.getRagIntentConfig.format(projectId, botId), {
      method: "PUT",
      loading: true,
      successMsg: true,
      data: payload,
      ...options,
    });
  };

export const getIntegrations = (projectId) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.getIntegration.format(projectId), {
    loading: true,
    successMsg: false,
  });
};

export const updateWhatsappOnboarding = (projectId, payload) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.postWhatsappOnboarding.format(projectId), {
    method: "POST",
    loading: true,
    successMsg: true,
    data: payload,
  });
};

export const updateFacebookMessengerOnboarding = (projectId, payload) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.postMessengerOnboarding.format(projectId), {
    method: "POST",
    loading: true,
    successMsg: true,
    data: payload,
  });
};
export const updateInstagramOnboarding = (projectId, payload) => async (_dispatch, _getState) => {
  return Network.request(apiUrlOnboarding.postInstagramOnboarding.format(projectId), {
    method: "POST",
    loading: true,
    successMsg: true,
    data: payload,
  });
};

export const updateOnboardingStatus =
  (projectId, payload, options = {}) =>
  async (_dispatch, _getState) => {
    return Network.request(apiUrlOnboarding.getOnboarding.format(projectId), {
      method: "POST",
      loading: true,
      successMsg: false,
      data: payload,
      ...options,
    });
  };
