import { useCallback, useEffect, useRef, useState } from "react";

import classNames from "classnames";
import Lottie from "lottie-react";
import { MdBackup } from "react-icons/md";

import styled from "@emotion/styled";

import Network from "~helpers/Network";
import Utils from "~helpers/Utils";

const StyledLottie = styled(Lottie)`
  border: 1px dashed var(--bs-gray-400);
  overflow: hidden;
  position: absolute;
  box-sizing: content-box;
  width: 80px;
  height: 80px;
`;

import "./style.scss";

function validateAndEscapeURL(rawUrl) {
  let url = rawUrl;
  if (!url) return "";
  if (rawUrl?.startsWith("/")) {
    url = window.location.origin + rawUrl;
  } else if (rawUrl?.startsWith("blob:")) {
    return rawUrl;
  }
  try {
    const sanitizedUrl = new URL(url);
    return sanitizedUrl.href;
  } catch (e) {
    console.error("Invalid URL", url);
    return "";
  }
}

export default function PRProfile({ className, disableSelect, img, onFileChange, circle, style, ...rest }) {
  const [uploadDisplay, setUploadDisplay] = useState(false);
  const [blobUri, setBlobUri] = useState(null);
  const [imageError, setImageError] = useState(false);
  const randStrRef = useRef(Utils.getId());

  const toggleShowUpload = useCallback(() => {
    setUploadDisplay(true);
  }, []);

  const toggleHideUpload = useCallback(() => {
    setUploadDisplay(false);
  }, []);

  const handleFileChange = async (event) => {
    await onFileChange?.(event.target.files?.[0], event);
    if (event.defaultPrevented) {
      return;
    }
    const blobUri = URL.createObjectURL(event.target.files?.[0]);
    setBlobUri(blobUri);
  };

  useEffect(() => {
    setImageError(false);
    if (typeof img === "string" && img.startsWith("blob:") && img !== blobUri) {
      setBlobUri(img);
    }
  }, [img, blobUri]);

  const isImageExist = (img || blobUri) && !imageError;
  return (
    <div
      {...rest}
      className={classNames("pr-profile position-relative", className)}
      style={{
        // backgroundImage: `url(${img || blobUri})`,
        cursor: disableSelect ? "default" : "pointer",
        ...style,
      }}
      onMouseEnter={toggleShowUpload}
      onMouseLeave={toggleHideUpload}
    >
      <img
        key={img || blobUri}
        alt=""
        height="80px"
        src={validateAndEscapeURL(img || blobUri)}
        style={{
          border: "1px dashed var(--bs-gray-400)",
          overflow: "hidden",
          position: "absolute",
          boxSizing: "content-box",
          ...(circle ? { borderRadius: "50%" } : {}),
        }}
        width="80px"
        onError={(e) => {
          setImageError(true);
          //clear broken icon
          e.target.style.display = "none";
        }}
      />
      <label
        htmlFor={`img-upload-${randStrRef.current}`}
        style={{
          cursor: disableSelect ? "default" : "pointer",
          pointerEvents: disableSelect ? "none" : "auto",
          zIndex: 1,
        }}
      >
        <div type="file">
          {(!isImageExist || uploadDisplay) && !disableSelect && (
            <div>
              <MdBackup
                style={{
                  width: "32px",
                  height: "32px",
                  color: "white",
                  opacity: imageError && !uploadDisplay ? 0.8 : 1,
                }}
              />
            </div>
          )}
        </div>
      </label>
      <input id={`img-upload-${randStrRef.current}`} type="file" onChange={handleFileChange} />
    </div>
  );
}

export function PRProfileLottie({ className, disableSelect, img, onFileChange, style, ...rest }) {
  const [uploadDisplay, setUploadDisplay] = useState(false);
  const [imageError, setImageError] = useState(false);
  const randStrRef = useRef(Utils.getId());

  const [lottieData, setLottieData] = useState(null);

  useEffect(() => {
    const requestUrl = img;

    if (img instanceof File) {
      const reader = new FileReader();
      reader.onload = function (e) {
        let obj;
        try {
          obj = JSON.parse(e.target.result);
        } catch {}
        if (obj?.v) {
          setLottieData(obj);
          setImageError(false);
        } else {
          setImageError(true);
        }
      };
      reader.readAsText(img);
      return;
    }

    if (!requestUrl?.startsWith("http") && !requestUrl?.startsWith("blob:")) return;

    Network.request(requestUrl)
      .then((res) => {
        if (res?.v) {
          setLottieData(res);
        } else {
          setImageError(true);
        }
      })
      .catch((err) => {
        setImageError(true);
      });
  }, [img]);

  const toggleShowUpload = useCallback(() => {
    setUploadDisplay(true);
  }, []);

  const toggleHideUpload = useCallback(() => {
    setUploadDisplay(false);
  }, []);

  const handleFileChange = async (event) => {
    await onFileChange?.(event.target.files?.[0], event);
    if (event.defaultPrevented) {
      return;
    }
  };

  useEffect(() => {
    setImageError(false);
  }, [img]);

  const isImageExist = img && !imageError;
  return (
    <div
      {...rest}
      className={classNames("pr-profile position-relative", className)}
      style={{
        // backgroundImage: `url(${img || blobUri})`,
        cursor: disableSelect ? "default" : "pointer",
        ...style,
      }}
      onMouseEnter={toggleShowUpload}
      onMouseLeave={toggleHideUpload}
    >
      <StyledLottie key={img} animationData={lottieData} />
      <label
        htmlFor={`img-upload-${randStrRef.current}`}
        style={{
          cursor: disableSelect ? "default" : "pointer",
          pointerEvents: disableSelect ? "none" : "auto",
          zIndex: 1,
        }}
      >
        <div type="file">
          {(!isImageExist || uploadDisplay) && !disableSelect && (
            <div>
              <MdBackup
                style={{
                  width: "32px",
                  height: "32px",
                  color: "white",
                  opacity: imageError && !uploadDisplay ? 0.8 : 1,
                }}
              />
            </div>
          )}
        </div>
      </label>
      <input id={`img-upload-${randStrRef.current}`} type="file" onChange={handleFileChange} />
    </div>
  );
}
